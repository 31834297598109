<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ $t('reports.daily-sales.title') }}
          </h1>
          <h2 class="subtitle">
            {{ $t('reports.daily-sales.subtitle') }}
          </h2>
        </div>
      </div>
    </section>
    <div :closable="false">
      <section>
        <div class="columns mb-5">
          <!-- Datepicker "from" -->
          <div class="column is-4">
            <b-field :label="$t('reports.daily-sales.fields.datepicker.from')">
              <b-datepicker
                v-model="selectedDatePickerFrom"
                :locale="locale"
                :placeholder="$t('reports.daily-sales.fields.datepicker.placeholder')"
                icon="calendar"
                trap-focus
                :max-date="maxDatePickerFrom">
              </b-datepicker>
            </b-field>
          </div>
          <!-- Datepicker "to" -->
          <div class="column is-4">
            <b-field :label="$t('reports.daily-sales.fields.datepicker.to')">
              <b-datepicker
                v-model="selectedDatePickerTo"
                :locale="locale"
                :placeholder="$t('reports.daily-sales.fields.datepicker.placeholder')"
                icon="calendar"
                trap-focus
                :max-date="maxDatePickerTo">
              </b-datepicker>
            </b-field>
          </div>
          <!-- Button show report -->
          <div class="column is-4 is-flex is-align-items-flex-end is-justify-content-space-around">
            <b-button
              type="is-primary"
              size="is-medium"
              @click="listDailyInventory"
              >
              {{ $t('reports.buttons.show') }}
            </b-button>
            <b-button
              type="is-warning"
              size="is-medium"
              @click="downloadReport"
              >
              {{ $t('reports.buttons.download-report') }}
            </b-button>
          </div>
        </div>
      </section>
      <section>
        <daily-sale-table v-show="isVisibleTable"
        :data="tableData"
        :columns="columns"
        :dateRange="dateRange"
        />
      </section>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'
import { dateToUnixEpoch, dateToStartDay, dateToEndDay, dateToHumanReport } from '../../../utils/dateFormat'
import { getUnixEpochRange } from '../../../utils/dateFunctions'
import DailySaleService from '../../../services/reports/dailySaleService'
import { createTableStructure } from '../../../utils/dailySales/createTableStructure'
import DailySaleTable from './Table.vue'
import { tableColumns } from '../../../utils/dailySales/tableColumns'
import createLinkToDownload from '../../../mixins/createLinkToDownload'

export default {
  name: 'DailySale',
  mixins: [
    createLinkToDownload
  ],
  components: {
    DailySaleTable,
  },
  data() {
    return {
      selectedDatePickerFrom: new Date(),
      selectedDatePickerTo: new Date(),
      locale: undefined,
      tableData: [],
      columns: [],
      dateRange: [],
      isVisibleTable: false,
      isFullPage: false,
      isLoading: false,
      maxDatePickerTo: new Date(),
      maxDatePickerFrom: new Date(),
    }
  },
  watch: {
    selectedDatePickerTo(value) {
      this.maxDatePickerFrom = value
      if(this.selectedDatePickerFrom.getTime() > value.getTime()) {
        this.selectedDatePickerFrom = value
      }
    }
  },
  methods: {
    async listDailyInventory() {
      this.tableData = []
      this.columns = []
      this.dateRange = []
      this.isVisibleTable = false
      this.isLoading = true
      try {
        this.dateRange = getUnixEpochRange(this.selectedDatePickerFrom, this.selectedDatePickerTo)

        this.columns = tableColumns(this.dateRange)

        const { data } = await DailySaleService.getList(this.getParametersToRequest())

         if(_isEmpty(data)) {
          throw new Error()
        }

        this.tableData = createTableStructure(data, this.dateRange)

        this.isVisibleTable = true

        this.isLoading = false

      }
      catch(err) {
        console.log(err)
      }
    },
    async downloadReport() {
      this.isLoading = true
      try {
        const { data } = await DailySaleService.getDownload(this.getParametersToRequest())

        this.downloadFile(data, this.$t('reports.daily-sales.filename', {from: dateToHumanReport(this.selectedDatePickerFrom), to: dateToHumanReport(this.selectedDatePickerTo)}))

        this.isLoading = false
      }
      catch(err) {
        console.log(err)
      }
    },
    getParametersToRequest(){
      const startDate = dateToStartDay(this.selectedDatePickerFrom)

      const endDate = dateToEndDay(this.selectedDatePickerTo)

      return {
        parameters: {
          startDate: dateToUnixEpoch(startDate),
          endDate: dateToUnixEpoch(endDate)
        }
      }
    }
  }
}
</script>
