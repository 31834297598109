import { put } from './httpService'

const context = 'auth'

const login = (params) => put(
    `${context}`,
    { data: { ...params } }
  )

export default {
  login
}
