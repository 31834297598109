<template>
  <div class="block is-size-5">
    <b-table
      striped
      :data="details"
      :selected.sync="selectedRow"
      :loading="isLoading"
      @click="handleRowClick"
    >
      <b-table-column
        field="productName"
        :label="$t('orders.details.headers.productName')"
        v-slot="props"
      >
        <span :class="{ 'has-text-info': props.row.priceType === 'vip-customer' }">
          {{ props.row.productName }}
        </span>
        <b-tooltip
          v-if="props.row.priceType === 'vip-customer'"
          :label="$t('orders.details.labels.vipCustomerPrice')"
          type="is-info">
          <b-icon
            icon="user-tag"
            type="is-info" />
        </b-tooltip>
      </b-table-column>
      <b-table-column field="priceValue" :label="$t('orders.details.headers.price')" v-slot="props">
        {{ props.row.priceValue | toFormatMoney }}
      </b-table-column>
      <b-table-column
        field="orderQty"
        :label="$t('orders.details.headers.quantity')"
        v-slot="props"
      >
        {{ props.row.orderQty }}
      </b-table-column>
      <b-table-column field="totalQty" :label="$t('orders.details.headers.totalQuantity')" v-slot="props">
        {{ props.row.totalQty }}
      </b-table-column>
      <b-table-column field="totalPriceValue" :label="$t('orders.details.headers.totalPrice')" v-slot="props">
        {{ props.row.totalPriceValue | toFormatMoney }}
      </b-table-column>
      <b-table-column v-slot="props" :meta="{ type: 'action' }">
        <b-button type="is-danger" icon-right="ban" @click="removeItem" :data-product_id="props.row.productId" :data-price_id="props.row.priceId" />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash'
import Big from 'big.js'

import orderService from '../services/orderService'
import transferService from '../services/transferService'

export default {
  name: 'OrderDetails',
  props: ['id', 'currentTransferId'],
  data: () => ({
    isLoading: false,
    details: [],
    selectedRow: undefined
  }),
  watch: {
    $route(newVal) {
      newVal.meta?.showRemoveDialog && this.showRemoveDialog()
    }
  },
  methods: {
    async load() {
      this.isLoading = true

      try {
        const { data } = await orderService.getDetails(this.id)

        const details = _.chain(data)
          .map(detail => {
            const orderQty = detail.qty / detail.priceQty
            return {
              productId: detail.productId,
              productName: this.$t('orders.details.cells.name', { name: detail.productName, qty: detail.priceQty }),
              totalQty: detail.qty,
              orderQty,
              priceId: detail.priceId,
              priceQty: detail.priceQty,
              priceType: detail.priceType,
              priceValue: detail.priceValue,
              totalPriceValue: Big(detail.priceValue).times(orderQty).toFixed(2)
            };
          })
          .sortBy('productName')
          .value()

        this.details = details

        this.$emit('detailsloaded', details)
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    handleRowClick(/*row*/) {
      // TODO: Unused. Consider to remove.
      //this.$emit('rowclick', row)
    },
    removeItem(e) {
      const { product_id, price_id } = e.currentTarget.dataset
      this.$router.push(`/check-out/${this.id}/remove/products/${product_id}/prices/${price_id}`)
    },
    showRemoveDialog() {
      const productId = this.$route.params.productId
      const priceId = this.$route.params.priceId

      this.$buefy.dialog.confirm({
        message: this.$t(`orders.messages.removeItem.confirm`),
        confirmText: this.$t('common.yes'),
        cancelText: this.$t('common.no'),
        onCancel: () => this.onActionCompleted({ type: 'removeItem', outcome: 'cancel' }),
        onConfirm: async () => {
          this.isLoading = true

          const item = this.details.find(x => x.productId === productId && x.priceId === priceId)
          const transferDetails = [{
            productId,
            priceId,
            fromBinType: 'picks',
            toBinType: 'store',
            qty: item.totalQty
          }]

          try {
            const { data } = await transferService.addDetails(this.currentTransferId, transferDetails)

            if(_.isEmpty(data)) {
              throw new Error()
            }

            this.details = this.details.filter(x => x.productId !== productId || x.priceId !== priceId)

            this.onActionCompleted({
              type: 'removeItem',
              outcome: 'success'
            })
          } catch(error) {
            console.error(error)

            this.onActionCompleted({
              type: 'removeItem',
              outcome: 'failure'
            })
          }
        }
      })
    },
    onActionCompleted(event) {
      const toastType = ({
        'success': 'is-success',
        'failure': 'is-danger'
      })[event.outcome]

      if (toastType) {
        this.$buefy.toast.open({
          message: this.$t(`orders.messages.${event.type}.${event.outcome}`, event.data),
          type: toastType
        })
      }

      this.$emit('detailsloaded', this.details)
      
      this.isLoading = false

      this.$router.back()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.input-animated-icon .icon {
  animation: fa-spin 2s linear infinite;
}
</style>
