import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { default as es } from './es.json';

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'es',
  messages: {
    es
  }
})
