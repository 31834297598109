import { securePost, securePostToDownload } from './httpServiceSecure'

const context = 'reportes'

const getList = (data) => securePost(`${context}/ventas`, { data })

const getDownload = (data) => securePostToDownload(`${context}/ventas/download`, { data })

export default {
  getList,
  getDownload
}
