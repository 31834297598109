
import { dateToUnixEpoch } from '../../utils/dateFormat'

import { formatMoney } from '../format'

import _uniqBy from 'lodash/uniqBy'

export const createTableStructure = (dataAPI, dateRange) => {

  let tableData = []

  const productList = _uniqBy(dataAPI.data, 0)

  for(let product in productList) {
    tableData.push({
      'id': productList[product][0],
      'name': productList[product][1]
    })
  }

  for(let date in dateRange){

    let startDate = dateRange[date]

    let getTimeToDate = dateRange[date].getTime()

    for(let row in tableData) {
      tableData[row][`price_${getTimeToDate}`] = 0

      tableData[row][`quantity_${getTimeToDate}`] = 0

      tableData[row][`total_${getTimeToDate}`] = formatMoney(0)
    }

    let dataFilter = dataAPI.data.filter(element => element[2] == dateToUnixEpoch(startDate))

    for(let elementDataFilter in dataFilter){

      let findIndex = tableData.findIndex(element => element.id === dataFilter[elementDataFilter][0])

      tableData[findIndex][`price_${getTimeToDate}`] = formatMoney(dataFilter[elementDataFilter][4]) ?? formatMoney(0)

      tableData[findIndex][`quantity_${getTimeToDate}`] = dataFilter[elementDataFilter][3] ?? 0

      tableData[findIndex][`total_${getTimeToDate}`] = formatMoney(dataFilter[elementDataFilter][5])
    }
  }

  return tableData
}
