<template>
  <div class="block is-size-5">
    <b-table
      striped
      :data="data"
    >
      <b-table-column
        field="productName"
        :label="$t('orders.details.headers.productName')"
        v-slot="props"
      >
        {{ props.row.productName }}
      </b-table-column>
      <b-table-column
        field="orderQty"
        :label="$t('orders.details.headers.quantity')"
        v-slot="props"
      >
        {{ props.row.orderQty }}
      </b-table-column>
      <b-table-column field="priceValue" :label="$t('orders.details.headers.price')" v-slot="props">
        {{ props.row.priceValue | toFormatMoney }}
      </b-table-column>
      <b-table-column field="totalQty" :label="$t('orders.details.headers.totalQuantity')" v-slot="props">
        {{ props.row.totalQty }}
      </b-table-column>
      <b-table-column field="totalPriceValue" :label="$t('orders.details.headers.totalPrice')" v-slot="props">
        {{ props.row.totalPriceValue | toFormatMoney }}
      </b-table-column>
    </b-table>
  </div>
</template>
<script>
export default {
  name: 'ProductTable',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>
