<template>
  <div class="block is-size-6">
    <b-table
      striped
      :data="data"
      :loading="isLoading"
      :row-class="hiddenRow"
    >
      <b-table-column field="productName" :label="$t('orders.details.headers.productName')" v-slot="props" :searchable="false">
        <span :class="{ 'has-text-info': props.row.priceType === 'vip-customer' }">
          {{ props.row.productName }}
        </span>
        <b-tooltip
          v-if="props.row.priceType === 'vip-customer'"
          :label="$t('orders.details.labels.vipCustomerPrice')"
          type="is-info">
          <b-icon
            icon="user-tag"
            type="is-info" />
        </b-tooltip>
      </b-table-column>
      <b-table-column field="priceValue" :label="$t('orders.details.headers.price')" v-slot="props">
        {{ props.row.priceValue | toFormatMoney }}
      </b-table-column>
      <b-table-column field="lastProductionQty" :label="$t('orders.details.headers.lastProductionQty')" v-slot="props">
        {{ props.row.lastProductionQty }}
      </b-table-column>
      <b-table-column field="availableQty" :label="$t('orders.details.headers.availableQuantity')" v-slot="props">
        {{ props.row.availableQty }}
      </b-table-column>
      <b-table-column field="orderQty" :label="$t('orders.details.headers.quantity')" v-slot="props">
        <b-numberinput
          controls-position="compact"
          :placeholder="0"
          :min="0"
          v-model="props.row.orderQty"
          @input="updatedOrderQty(props.row)"
          :loading="props.row.locked"
          :disabled="props.row.locked"
        />
      </b-table-column>
      <b-table-column field="totalQty" :label="$t('orders.details.headers.totalQuantity')" v-slot="props">
        {{ props.row.totalQty }}
      </b-table-column>
      <b-table-column field="totalPriceValue" :label="$t('orders.details.headers.totalPrice')" v-slot="props">
        {{ props.row.totalPriceValue | toFormatMoney }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash'
import Big from 'big.js'

import binService from '../services/binService'
import transferService from '../services/transferService'
import orderService from '../services/orderService'
import { hiddenRowTable } from '../utils/hiddenRowTable'

const customTotalPrice =
  (price, orderQty) =>
    Big(price).times(orderQty).toFixed(2)

export default {
  name: 'OrderDetailsEdit',
  props: ['id', 'transferId', 'dataBySearch'],
  data: () => ({
    data: [],
    isLoading: true
  }),
  watch: {
    data: {
      deep: true,
      handler(value) {
        if (this.isLoading) {
          return
        }

        this.debouncedQuantityChange(value)
      }
    }
  },
  created: function() {
    this.debouncedQuantityChange = _.debounce(this.applyQtyChange, 15000)
  },
  methods: {
    async load() {
      this.isLoading = true

      try {
        const { orderDetails, productInventory } = {
          orderDetails: await orderService.getDetails(this.id),
          productInventory: await binService.listDetailsByType('store', 'all')
        }

        const orderDetailsByProductAndPriceId = _.keyBy(orderDetails.data, x => `${x.productId}-${x.priceId}`)
        const details = _.chain(productInventory)
          .get('data', [])
          .map(({ productId, productName, qty: availableQty, priceId, priceType, priceQty, priceValue, lastProductionQty }) => {
            const initialTotalQty = _.get(orderDetailsByProductAndPriceId, [ `${productId}-${priceId}`, 'qty' ], 0)
            const orderQty = initialTotalQty / priceQty

            return {
              productId,
              productName: this.$t('orders.details.cells.name', { name: productName, qty: priceQty }),
              availableQty,
              initialTotalQty,
              totalQty: initialTotalQty,
              orderQty,
              priceId,
              priceType,
              priceQty,
              priceValue: Big(priceValue).toFixed(2),
              totalPriceValue: customTotalPrice(priceValue, orderQty),
              lastProductionQty
            }
          })
          .sortBy('productName')
          .value();

        this.data = details

        this.$emit('detailsloaded', details)
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    async applyQtyChange(details) {
      const transferDetails = details
        .filter(({ initialTotalQty, totalQty }) => initialTotalQty !== totalQty)
        .map(({ totalQty, initialTotalQty, productId, priceId }) => {
          const detailQty = totalQty - initialTotalQty

          return {
            productId,
            priceId,
            fromBinType: 'store',
            toBinType: 'picks',
            qty: detailQty
          }
        })

      if (_.isEmpty(transferDetails)) {
        return
      }

      this.data = details.map(detail => detail.initialTotalQty !== detail.totalQty ? {
        ...detail,
        initialTotalQty: detail.totalQty
      } : detail)

      try {
        const { data } = await transferService.addDetails(this.transferId, transferDetails)

        if(_.isEmpty(data)) {
          throw new Error()
        }
      } catch (ex) {
        console.error(ex)
        this.load() // Execute, but don't wait
      }
    },
    async applyPendingQtyChange() {
      this.debouncedQuantityChange.cancel()
      await this.applyQtyChange(this.data)
    },
    updatedOrderQty(row) {
      const previousQty = _
        .chain(this.data)
        .find(x => x.productId === row.productId && x.priceId === row.priceId)
        .get('totalQty')
        .value()
      const rowTotalQty = row.orderQty * row.priceQty
      const diffQty = rowTotalQty - previousQty

      this.data = this.data.map(x => x.productId === row.productId ? {
        ...x,
        availableQty: x.availableQty - diffQty,
        totalQty: x.orderQty * x.priceQty,
        totalPriceValue: customTotalPrice(x.priceValue, x.orderQty)
      } : x)
      this.$emit('detailsloaded', this.data)
    },
    hiddenRow(row) {
      return hiddenRowTable(row, this.dataBySearch)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
