<template>
  <b-sidebar
    fullheight
    type="is-light"
    :open="isOpenMenu"
    position="fixed"
    :can-cancel="false"
  >
    <div class="p-1">
      <div class="block">
        <!--<img
          class="menu-logo"
          src="../assets/logo.png"
          alt="Bakery"
        />-->
      </div>
      <!-- TODO: Render menu based on route access per current role -->
      <b-menu class="is-custom-mobile pt-5">
        <b-menu-list>
          <b-menu-item
            icon="home"
            :label="$t('menu.items.home')"
            @click="handleMenuItemClick"
            data-path="/home"
          />
          <b-menu-item
            icon="shopping-basket"
            :label="$t('menu.items.orders')"
            @click="handleMenuItemClick"
            data-path="/orders"
            v-if="['admin', 'salesclerk'].includes(currentRole)"
          />
          <b-menu-item
            icon="cash-register"
            :label="$t('menu.items.checkOut')"
            @click="handleMenuItemClick"
            data-path="/check-out"
            v-if="['admin', 'cashier'].includes(currentRole)"
          />
          <b-menu-item
            icon="bread-slice"
            :label="$t('menu.items.products')"
            @click="handleMenuItemClick"
            data-path="/products"
            v-if="['admin', 'producer'].includes(currentRole)"
          />
          <b-menu-item
            icon="cookie"
            :label="$t('menu.items.production')"
            @click="handleMenuItemClick"
            data-path="/production"
            v-if="['admin', 'producer'].includes(currentRole)"
          />
          <b-menu-item
            icon="cookie-bite"
            :label="$t('menu.items.closingInventory')"
            @click="handleMenuItemClick"
            data-path="/closing-inventory"
            v-if="['admin', 'cashier'].includes(currentRole)"
          />
          <b-menu-item icon="file"
            v-if="['admin', 'producer', 'cashier'].includes(currentRole)"
            :expanded="false"
            >
            <template #label="props">
              <span >{{ $t('menu.items.reports.label') }}</span>
              <b-icon v-show="props.expanded" class="is-pulled-right p-t-22" :icon="'caret-up'"></b-icon>
              <b-icon v-show="!props.expanded" class="is-pulled-right p-t-22" :icon="'caret-down'"></b-icon>
            </template>
            <b-menu-item
              v-if="['admin', 'producer'].includes(currentRole)"
              icon="boxes"
              :label="$t('menu.items.reports.items.beginning-inventory')"
              @click="handleMenuItemClick"
              data-path="/reports/beginning-daily-inventory">
            </b-menu-item>
            <b-menu-item
              v-if="['admin', 'producer', 'cashier'].includes(currentRole)"
              icon="file-invoice-dollar"
              :label="$t('menu.items.reports.items.daily-sales')"
              @click="handleMenuItemClick"
              data-path="/reports/daily-sales">
            </b-menu-item>
          </b-menu-item>
          <b-menu-item
            icon="users"
            :label="$t('menu.items.users')"
            @click="handleMenuItemClick"
            data-path="/users"
            v-if="['admin'].includes(currentRole)"
          />
          <b-menu-item
            icon="sign-out-alt"
            :label="$t('menu.items.logOut')"
            @click="logOut"
          />
        </b-menu-list>
      </b-menu>
    </div>
    <!--<div class="p-1 menu-bottom">
      <div role="button" class="button-reduce" @click="reduceMenu">
        <b-icon v-show="reduce" icon="angle-double-right" />
        <b-icon v-show="!reduce" icon="angle-double-left" />
      </div>
    </div>-->
  </b-sidebar>
</template>

<script>
import _get from 'lodash/get'

import { clearRefreshApiKeyTimeout, getClaims } from '../utils/auth'

export default {
  name: 'Menu',
  props: {
    isOpenMenu: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    reduce: false,
    currentRole: undefined
  }),
  created() {
    this.currentRole = _get(getClaims(), 'user.role')
  },
  methods: {
    handleMenuItemClick(e) {
      this.$emit('update:isOpenMenu', !this.isOpenMenu)
      const path = e.currentTarget.dataset.path
      this.$router.push(path)
    },
    logOut() {
      clearRefreshApiKeyTimeout()
      localStorage.clear()
      this.$router.push('/login')
    },
    reduceMenu() {
      this.reduce = !this.reduce
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "~bulma/sass/utilities/_all";

.icon.is-small {
  height: 3rem;
  width: 3rem;
}

.p-t-22 {
  padding-top: 22px;
}

.p-1 {
  padding: 1em;
}

.b-sidebar .sidebar-content {
  justify-content: space-between;

  .menu {
    .menu-list li a {
      white-space: nowrap;
    }
  }

  &.is-mini {
    width: 50px;
  }

  @media screen and (max-width: 1023px) {
    &.is-mini-mobile {
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        .menu-list {
          li {
            a {
              span:nth-child(2) {
                display: none;
              }
            }
            ul {
              padding-left: 0;

              li {
                a {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &.is-mini {
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        .menu-list {
          li {
            a {
              span:nth-child(2) {
                display: none;
              }
            }
            ul {
              padding-left: 0;

              li {
                a {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.menu-logo {
  width: 42px;
}

.menu-bottom {
  display: flex;
  justify-content: flex-end;
}

.button-reduce {
  border-radius: 2px;
  padding: 0.5em;
}

.menu-list a:hover,
.button-reduce:hover {
  color: $link-invert;
  background-color: $link-hover;
}
</style>
