 <template>
  <div class="buttons fixed zi-40">
    <b-button
      size="is-medium"
      type="is-light"
      :icon-right="iconRight"
      @click="toogleHamburguer"/>
  </div>
</template>
<script>
export default {
  name: 'NavBar',
  props: {
    isOpenMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconRight: "bars"
    };
  },
  watch: {
    isOpenMenu(value) {
      this.iconRight = (value) ? 'times' : 'bars';
    }
  },
  methods: {
    toogleHamburguer() {
      this.$emit('update:isOpenMenu', !this.isOpenMenu)
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
}
.zi-40 {
  z-index: 40;
}
</style>
