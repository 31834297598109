import { default as apis } from '../../../apis.json'

const context = `${apis.api.reports.url}api/v1`

const request = (url, options) => new Promise(
  (resolve, reject) =>
    fetch(url, {
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(options && options.headers)
      }
    })
      .then(response => {
        response.json().then(data => {
          if (response.ok) {
            resolve({ data })
          } else {
            reject({ data, status: response.status })
          }
        })
      })
      .catch(error => {
        reject(error);
      })
)

const requestWithBody = method => (url, { headers, data }) => request(`${context}/${url}`, {
  headers,
  method: method,
  body: JSON.stringify(data)
})

export const post = requestWithBody('POST')
