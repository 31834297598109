<template>
  <data-list v-if="locations" type='users' :columns="columns" name-field="login" />
</template>

<script>
import _ from 'lodash'

import i18n from '../i18n';
import locationService from '../services/locationService'
import DataList from './DataList.vue'

export default {
  name: 'Users',
  components: {
    DataList
  },
  data: () => ({
    locations: null,
    columns: null
  }),
  async mounted () {
    const { data: locations } = await locationService.list()
    this.locations = locations

    this.columns = [
        {
          field: 'login'
        },
        {
          field: 'firstNames'
        },
        {
          field: 'lastNames'
        },
        {
          field: 'role',
          format: user => i18n.tc(`users.roleOptions.${user.role}`)
        },
        {
          field: 'locations',
          format: (user) => _.isEmpty(user.locations)
            ? i18n.tc('users.edit.fields.allLocations')
            : user.locations.map(id => _.find(locations, { id })).filter(x => x).map(x => x.name).join(", ")
        }
      ]
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
