import { dateToStartDay, dateToNextDay, dateToEndDay } from './dateFormat'

export const getUnixEpochRange = (startDate, endDate) => {

  let dateRange = [];

  let dateTomorrow = dateToStartDay(startDate)

  while(dateTomorrow.getTime() <= endDate.getTime()) {

    let dateTomorrowEnd = dateToEndDay(dateTomorrow)

    dateRange.push(dateTomorrowEnd)

    dateTomorrow = dateToNextDay(dateTomorrow)

  }

  return dateRange
}

export const getUnixEpochRangeStartDate = (startDate, endDate) => {

  let dateRange = [];

  let dateTomorrow = startDate

  while(dateTomorrow.getTime() <= endDate.getTime()) {

    let dateTomorrowStart = dateToStartDay(dateTomorrow)

    dateRange.push(dateTomorrowStart)

    dateTomorrow = dateToNextDay(dateTomorrow)

  }

  return dateRange
}
