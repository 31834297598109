
export const hiddenRowTable = (row, data, fieldToSearch = 'productName') => {
  if(data.isEmptyValue) return ''

  if(!data.isEmptyValue && data.searchedProducts.length === 0) return 'is-hidden'

  for(let search in data.searchedProducts) {
    //If found product dont set any class
    if(row[fieldToSearch] === data.searchedProducts[search][fieldToSearch]) return ''
  }
  //if does not found prodcut set hidden class
  return 'is-hidden';
}
