<template>
  <div ref="receipt" class="receipt">
    <hr class="cut">
    <div class="receipt-container">
      <div class="company">Panadería Rey</div>
      <div class="ticket-number">No. {{ order.correlative }}</div>
      <div class="date">{{ order.createdOn | toLocalDateTime('DD/MM/YYYY hh:mm:ss A') }}</div>
      <hr class="separator">
      <div class="item-wrapper" v-for="item in details" :key="item.id">
        <div class="item">
          <div>{{ item.totalQty }}&emsp;{{ item.productName }}</div>
          <div>{{ item.totalPriceValue | toFormatMoney }}</div>
        </div>
      </div>
      <hr class="separator">
      <div class="item-wrapper">
        <div class="item">
          <span class="total-label">Total</span>
          <span class="total-value">{{ total | toFormatMoney}}</span>
        </div>
      </div>
    </div>
    <hr class="cut">
  </div>
</template>
<script>
import { Printd } from 'printd'

export default {
  name: 'PrintOrder',
  props: {
    order: {
      type: Object
    },
    details: {
      type: Array
    },
    total: {
      type: String,
      default: '0'
    }
  },
  methods: {
    print() {
      return new Promise(resolve => {
        const printd = new Printd()

        //printd.onBeforePrint((event) => console.log(event, "called before printing!"))
        printd.onAfterPrint(() => {
          resolve();
        })

        printd.print(this.$refs.receipt, [`
          html, body {
            width: 48mm;
          }

          body {
            font-family: 'Courier New', Courier, monospace;
          }

          .receipt-container {
            margin-top: 10mm;
            margin-bottom: 10mm;
          }

          .company {
            text-align: center;
            font-size: 6mm;
            font-2eight: 700;
            margin-bottom: 6mm;
          }

          .item-wrapper {
            display: block;
          }

          .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }

          hr.separator {
            display: block;
            height: 4px;
            border: 0;
            border-top: 4px solid #000;
            margin: 1em 0;
            padding: 0;
          }
        `])
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .receipt {
    display: none;
  }
</style>
