<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          {{$t('home.title')}}
        </h1>
        <h2 class="subtitle">
          {{$t('home.subtitle')}}
        </h2>
        <weekly-sales v-if="['admin'].includes(currentRole)"/>
      </div>
    </div>
  </section>
</template>

<script>
//Components
import WeeklySales from './Charts/WeeklySales/Index.vue'

//Mixins
import currentRoleMixin from '../mixins/currentRoleMixin'

export default {
  name: 'Home',
  mixins: [currentRoleMixin],
  components: {
    WeeklySales
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
