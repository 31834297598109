export default {
  methods: {
    downloadFile(data, filename = 'my-file') {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = filename;
      a.click();
    }
  }
}
