<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{$t('checkOut.title')}}
          </h1>
          <h2 class="subtitle">
            {{$t('checkOut.subtitle')}}
          </h2>
        </div>
      </div>
    </section>
    <section>
      <order-list status="prepared" @rowclick="handleRowClick" />
    </section>
  </div>
</template>

<script>
import OrderList from './OrderList.vue'

export default {
  name: 'CheckOut',
  components: {
    'order-list': OrderList
  },
  methods: {
    handleRowClick({ id }) {
      this.$router.push(`/check-out/${id}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
