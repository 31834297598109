<template>
  <div>
    <section class="hero">
      <div class="hero-body is-flex is-justify-content-space-between">
        <div class="container">
          <h2 class="subtitle">
            {{$t('orders.edit.headers.orderCorrelative')}}
          </h2>
          <h1 class="title">
            {{correlative}}
          </h1>
        </div>
        <div class="container">
          <h2 class="subtitle">
            {{$t('checkOut.edit.headers.total')}}
          </h2>
          <h1 class="title">
            {{ total | toFormatMoney }}
          </h1>
        </div>
      </div>
    </section>
    <section>
      <div class="block">
        <b-field :label="$t('orders.edit.fields.clientName')" custom-class="is-large">
          <b-input
            class="input-animated-icon"
            v-model="clientName"
            size="is-large"
            :placeholder="$t('orders.edit.fields.clientName')"
            :icon-right="isSavingClientName ? 'sync' : undefined"
          />
        </b-field>
      </div>
      <div class="block is-flex is-justify-content-space-between">
        <div class="buttons">
          <!--<b-button
            :label="$t(`orders.edit.actions.addProduct`)"
            type="is-primary"
            size="is-medium"
            @click="$router.push(`/orders/edit/${id}/choose-product`)"
          />-->
        </div>
        <div class="buttons">
          <b-button
            :label="$t(`orders.edit.actions.sendToCashier`)"
            type="is-success"
            size="is-medium"
            @click="prepareOrder"
          />
          <b-button
            :label="$t(`orders.edit.actions.close`)"
            type="is-danger is-light"
            size="is-medium"
            @click="closeOrder"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <search-input
            :data="details"
            @getResultOfSearchInput="(value) => { detailsBySearch = value }"
          />
        </div>
      </div>
      <order-details
        ref="orderDetails"
        :id="id"
        :transferId="currentTransferId"
        @rowclick="handleRowClick"
        @detailsloaded="handleDetailsLoaded"
        :dataBySearch="detailsBySearch"
      />
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal
      v-model="showModal"
      :has-modal-card="hasModalCard"
      :full-screen="!hasModalCard"
      @close="$router.back()"
    >
      <router-view v-on:completed="onActionCompleted" v-bind="modalProps" />
    </b-modal>
    <print-order
      ref="printOrder"
      :order="processedOrder"
      :total="total"
      :details="detailsWithQuantity"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Big from 'big.js'

import OrderDetailsEdit from './OrderDetailsEdit.vue'
import orderService from '../services/orderService'
import SearchInput from './SearchInput/index.vue'
import PrintOrder from './PrintOrder.vue'

export default {
  name: 'EditOrder',
  components: {
    'order-details': OrderDetailsEdit,
    'search-input': SearchInput,
    'print-order': PrintOrder
  },
  props: ['id'],
  data: () => ({
    isLoading: false,
    correlative: undefined,
    clientName: undefined,
    savedClientName: undefined,
    isSavingClientName: false,
    details: [],
    showModal: false,
    hasModalCard: false,
    modalProps: undefined,
    currentTransferId: undefined,
    selectedRow: undefined,
    total: '0',
    detailsBySearch: {},
    processedOrder: {}
  }),
  computed: {
    detailsWithQuantity: function() {
      return this.details.filter(x => x.orderQty > 0)
    }
  },
  async mounted () {
    if (!this.id) {
      return
    }

    await this.loadOrder()
  },
  async beforeDestroy () {
    await this.processOrder(false, false);
  },
  watch: {
    $route(newVal) {
      this.showModal = !!newVal.meta?.showModal
      this.hasModalCard = !!newVal.meta?.hasModalCard
    },
    clientName: function(value) {
      this.debouncedSaveClientName(value)
    }
  },
  created: function() {
    this.debouncedSaveClientName = _.debounce(this.saveClientName, 10000)
  },
  methods: {
    async loadOrder() {
      this.isLoading = true

      try {
        this.$refs.orderDetails.load()

        const { data: order } = await orderService.get(this.id)
        this.correlative = order.correlative
        this.clientName = order.clientName
        this.savedClientName = order.clientName
        this.currentTransferId = order.currentTransfer.id
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    handleDetailsLoaded(details) {
      this.details = details
      this.total = details.reduce(
        (sum, item) => sum.plus(item.totalPriceValue),
        Big(0)
      ).toFixed(2)
    },
    async saveClientName(value) {
      if (this.savedClientName === value || this.isSavingClientName) {
        return
      }

      this.isSavingClientName = true

      try {
        orderService.patch(this.id, {
          clientName: value
        })
        this.saveClientName = value
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isSavingClientName = false
      }
    },
    async prepareOrder() {
      await this.processOrder(true)
    },
    async closeOrder() {
      await this.processOrder(false);
    },
    async processOrder(prepare, goBack = true) {
      this.isLoading = true

      try {
        this.debouncedSaveClientName.cancel()

        await this.$refs.orderDetails.applyPendingQtyChange()

        if(this.clientName !== undefined || prepare) {
          const { data } = await orderService.patch(
            this.id,
            _.merge({
              clientName: this.clientName
            }, prepare && {
              status: 'prepared'
            })
          )

          if(_.isEmpty(data)) {
            throw new Error()
          }

          this.processedOrder = data
        }

        if (prepare) {
          this.isLoading = false

          this.$buefy.dialog.confirm({
            message: this.$t(`common.print`),
            confirmText: this.$t('common.yes'),
            cancelText: this.$t('common.no'),
            onCancel: () => this.$router.back(),
            onConfirm: async () => {
              await this.$refs.printOrder.print()
              this.$router.back()
            }
          })
        } else if (goBack) {
          this.$router.back()
        }
      } catch (ex) {
        this.$buefy.toast.open({
          message: this.$t('common.failure'),
          type: 'is-danger'
        })
        console.error(ex)

        this.isLoading = false
      }
    },
    async onActionCompleted({ type, outcome, data }) {
      switch(outcome) {
        case 'cancel':
          this.$router.back()
          break
        case 'success':
          switch (type) {
            case 'choose-product':
              this.$router.replace(`/orders/edit/${this.id}/add-product`)
              this.showAddProductModal(data)
              break
            case 'add-product':
              this.$router.back()
              await this.loadOrder()
              break
          }
      }
    },
    handleRowClick(/*row*/) {
      // TODO: Unused. Consider to remove.
      /*
      this.$router.push(`/orders/edit/${this.id}/add-product`)
      this.showAddProductModal({
        id: row.productId,
        name: row.productName,
        price: row.unitPrice,
      })
      */
    },
    showAddProductModal(data) {
      this.modalProps = {
        productId: data.id,
        productName: data.name,
        price: data.price,
        qty: _.chain(this.details).find({ productId: data.id }).get('qty', 0).value(),
        transferId: this.currentTransferId
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.input-animated-icon .icon {
  animation: fa-spin 2s linear infinite;
}
</style>
