<template>
  <div class="login-page columns">
    <div class="login-column column is-4">
      <section class="section">
        <div class="has-text-centered">
          <img class="login-logo" src="../assets/logo.png">
        </div>

        <b-field
          :label="$t('login.username')"
          :type="{ 'is-danger': hasError }"
        >
          <b-input rounded v-model="username" icon-right="user">
          </b-input>
        </b-field>

        <b-field
          :label="$t('login.password')"
          :type="{ 'is-danger': hasError }"
          :message="{ [$t('login.error')]: hasError }"
        >
          <b-input rounded type="password" v-model="password" icon-right="key">
          </b-input>
        </b-field>

        <div class="has-text-centered">
          <b-button class="login-signIn is-vcentered is-primary is-outlined" v-on:click="signIn" v-bind:loading="isLoading">
            {{$t('login.signIn')}}
          </b-button>
        </div>
      </section>
    </div>
    <div class="login-background column is-8">
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'

import authService from '../services/authService'
import locationService from '../services/locationService'
import { setRefreshApiKey, getClaims } from '../utils/auth'

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    isLoading: false,
    hasError: false
  }),
  methods: {
    async signIn() {
      try {
        this.isLoading = true

        // Login
        const { data: { apiKey } } = await authService.login({
          loginOrEmail: this.username,
          password: this.password
        })

        localStorage.setItem('apiKey', apiKey)
        localStorage.setItem('apiKeyExpirationTimestamp', moment().add(24, 'hours').unix())

        // Get location
        const { data: locations } = await locationService.list();

        const parsedJwt = getClaims(apiKey)

        const location = _.isEmpty(parsedJwt.user.locations)
          ? _.head(locations)
          : _.find(locations, { id: _.head(parsedJwt.user.locations) })

        console.log('Login', { parsedJwt, location })

        localStorage.setItem('location', JSON.stringify(location))

        setRefreshApiKey()

        const path = this.$route.query.redirect || '/home'

        this.$router.push(path)
      } catch (ex) {
        console.error(ex)
        this.password = ''
        this.hasError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.login {
  &-page.columns {
    margin: 0;
    flex: 1;
    align-items: stretch;
  }

  &-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-background {
    background-image: url('../assets/login-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    box-shadow: inset 24px 4px 64px -24px rgba(71,71,71,1);

    @media (max-width: 769px) {
      display: none;
    }
  }

  &-logo {
    margin: 0 auto;
    margin-bottom: 50px;
    max-height: 100px;
  }

  &-input {
    border-radius: 50px;
  }

  &-signIn {
    margin-top: 20px;
    margin-bottom: 20px;
    min-width: 150px;
  }
}
</style>
