import i18n from '@/i18n/index.js'
import { dateToHumanReport } from '../dateFormat'

export const tableColumns = (dateRange) => {
  let columns = [
    {
      field: 'name',
      label: i18n.t('reports.daily-sales.table.headers.name'),
      sticky: true
    },
  ]

  for(let date in dateRange){
    let extraColumn = {}
    /*extraColumn = {
      field: `price_${dateRange[date].getTime()}`,
      label: i18n.t('reports.daily-sales.table.headers.price')
    }
    columns.push(extraColumn)*/

    extraColumn = {
      field: `quantity_${dateRange[date].getTime()}`,
      label: dateToHumanReport(dateRange[date])
    }
    columns.push(extraColumn)

    extraColumn = {
      field: `total_${dateRange[date].getTime()}`,
      label: ''
    }
    columns.push(extraColumn)

  }

  return columns
}
