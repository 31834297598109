<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{$t('orders.title')}}
          </h1>
          <h2 class="subtitle">
            {{$t('orders.subtitle')}}
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="block">
        <b-button
          :label="$t('orders.actions.add')"
          type="is-primary"
          size="is-medium"
          @click="createItem" />
      </div>
      <order-list status="preparing" @rowclick="handleRowClick" />
    </section>
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'

import OrderList from './OrderList.vue'
import orderService from '../services/orderService'

export default {
  name: 'Orders',
  components: {
    'order-list': OrderList
  },
  methods: {
    async createItem() {
      this.isLoading = true

      try {
        const { data } = await orderService.add({})

        if(_isEmpty(data)) {
          throw new Error()
        }

        this.$router.push(`/orders/edit/${data.id}`)
      }  catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    handleRowClick({ id }) {
      this.$router.push(`/orders/edit/${id}`)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
