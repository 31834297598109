
import { dateToUnixEpoch } from '../../utils/dateFormat'

import { formatMoney } from '../format'

import _uniqBy from 'lodash/uniqBy'

import i18n from '@/i18n/index.js'

export const createTableStructure = (dataAPI, dateRange) => {

  if(dataAPI.data.length === 0) return []

  let tableData = []

  const productList = _uniqBy(dataAPI.data, 0)

  const nameRowTotal = 'total'

  for(let product in productList) {
    tableData.push({
      'id': productList[product][0],
      'name': productList[product][1]
    })
  }

  tableData.push({
    'id': nameRowTotal,
    'name': i18n.t('reports.daily-sales.table.row.total'),
  })

  let indexRowTotal = tableData.findIndex(element => element.id === nameRowTotal)

  for(let date in dateRange){

    let startDate = dateRange[date]

    let getTimeToDate = dateRange[date].getTime()


    for(let row in tableData) {
      //tableData[row][`price_${getTimeToDate}`] = 0

      tableData[row][`quantity_${getTimeToDate}`] = 0

      tableData[row][`total_${getTimeToDate}`] = 0
    }

    //tableData[indexRowTotal][`price_${getTimeToDate}`] = ''

    let dataFilter = dataAPI.data.filter(element => element[2] == dateToUnixEpoch(startDate))

    for(let elementDataFilter in dataFilter){

      let findIndex = tableData.findIndex(element => element.id === dataFilter[elementDataFilter][0])

      //Fill products row
      //tableData[findIndex][`price_${getTimeToDate}`] = formatMoney(dataFilter[elementDataFilter][4]) ?? formatMoney(0)

      tableData[findIndex][`quantity_${getTimeToDate}`] = dataFilter[elementDataFilter][3] ?? 0

      tableData[findIndex][`total_${getTimeToDate}`] = formatMoney(dataFilter[elementDataFilter][4]) ?? formatMoney(0)

      //Fill total row
      tableData[indexRowTotal][`quantity_${getTimeToDate}`] += dataFilter[elementDataFilter][3] ?? 0

      tableData[indexRowTotal][`total_${getTimeToDate}`] += dataFilter[elementDataFilter][4] * 1
    }

    tableData[indexRowTotal][`total_${getTimeToDate}`] = formatMoney(tableData[indexRowTotal][`total_${getTimeToDate}`])
  }

  return tableData
}
