<template>
  <data-list type='products' :columns="columns" :details="details" name-field="name" />
</template>

<script>
import DataList from './DataList.vue'
import { formatMoney } from '../utils/format'

export default {
  name: 'Products',
  components: {
    DataList
  },
  data: () => ({
    columns: [
      {
        field: 'name'
      },
      {
        field: 'price',
        format: item => formatMoney(item.prices.find(x => x.qty === 1)?.price || 0)
      }
    ],
    details: {
      key: 'id',
      field: 'prices',
      columns: [
        {
          field: 'name',
          format: (item, $t) => $t(
            `products.list.cells.quantity${item.type === 'normal' ? 'Normal' : 'VipCustomer'}`,
            { qty: item.qty }
          )
        },
        {
          field: 'price',
          format: item => formatMoney(item.price || 0)
        }
      ]
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
