<template>
  <div class="modal-card" style="width: auto">
    <form @submit.prevent="submit">
      <header class="modal-card-head">
        <p class="modal-card-title">{{$t('users.edit.headers.new')}}</p>
      </header>
      <section class="modal-card-body">
        <b-field grouped>
          <b-field :label="$t('users.edit.fields.firstNames')">
            <b-input
              ref="firstNames"
              v-model.lazy="firstNames"
              :placeholder="$t('users.edit.fields.firstNames')"
              @focus="$event.target.select()"
              required
            />
          </b-field>
          <b-field :label="$t('users.edit.fields.lastNames')">
            <b-input
              ref="lastNames"
              v-model.lazy="lastNames"
              :placeholder="$t('users.edit.fields.lastNames')"
              @focus="$event.target.select()"
              required
            />
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field :label="$t('users.edit.fields.role')">
            <b-select
              ref="role"
              :placeholder="$t('users.edit.fields.role')"
              v-model.lazy="role"
            >
              <option value="admin">{{$t('users.roleOptions.admin')}}</option>
              <option value="producer">{{$t('users.roleOptions.producer')}}</option>
              <option value="salesclerk">{{$t('users.roleOptions.salesclerk')}}</option>
              <option value="cashier">{{$t('users.roleOptions.cashier')}}</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field :label="$t('users.edit.fields.location')">
            <b-select
              ref="locations"
              :placeholder="$t('users.edit.fields.location')"
              v-model.lazy="locationId"
            >
              <option
                v-for="option in locations"
                :value="option.id"
                :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-field grouped>
          <b-field :label="$t('users.edit.fields.login')">
            <b-input
              ref="login"
              v-model.lazy="login"
              :placeholder="$t('users.edit.fields.login')"
              @focus="$event.target.select()"
              required
            />
          </b-field>
          <b-field :label="$t('users.edit.fields.password')">
            <b-input
              ref="password"
              type="password"
              v-model.lazy="password"
              placeholder="**********"
              @focus="$event.target.select()"
              :required="!this.id"
            />
          </b-field>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :label="$t('common.cancel')"
          @click="cancel" />
        <b-button
          :label="id ? $t('common.update') : $t('common.add')"
          type="is-primary"
          native-type="submit" />
      </footer>
    </form>
  </div>
</template>

<script lang="js">
import _ from 'lodash'

import userService from '../services/userService';
import locationService from '../services/locationService'
import { getCurrentLocationId } from '../utils/auth';

export default {
  name: 'EditUser',
  props: ['id'],
  data: () => ({
    login: '',
    firstNames: '',
    lastNames: '',
    locationId: getCurrentLocationId(),
    role: undefined,
    password: '',
    isLoading: false,
    locations: [],
    moneyFormat: {} // HACK: Seems to be required by the money directive
  }),
  async mounted () {
    this.isLoading = true

    const { data: locations } = await locationService.list()
    this.locations = [{ id: '_', name: this.$t('users.edit.fields.allLocations') }, ...locations]

    if (this.id) {
      try {
        const { data } = await userService.get(this.id)
        this.login = data.login
        this.firstNames = data.firstNames
        this.lastNames = data.lastNames
        this.role = data.role
        this.locationId = _.get(data.locations, 0, '_')
      } catch (ex) {
        console.error(ex)
      }
    }

    this.isLoading = false

    const field = this.$route.query.field;

    if (field) {
      this.$refs[field].focus()
    } else {
      this.$refs.firstNames.focus()
    }
  },
  methods: {
    async submit() {
      this.isLoading = true

      const passwordObject = _.isEmpty(this.password) ? {} : { password: this.password };

      const { type, action } = this.id ? {
        type: 'update',
        action: userService.update(this.id, {
          login: this.login,
          firstNames: this.firstNames,
          lastNames: this.lastNames,
          locations: this.locationId === '_' ? [] : [this.locationId],
          role: this.role,
          ...passwordObject
        })
      } : {
        type: 'add',
        action: userService.add({
          login: this.login,
          firstNames: this.firstNames,
          lastNames: this.lastNames,
          locations: this.locationId === '_' ? [] : [this.locationId],
          role: this.role,
          password: this.password
        })
      }

      try {
        const { data } = await action

        if(_.isEmpty(data)) {
          throw new Error()
        }

        this.$emit('completed', { type, outcome: 'success', data: { name: this.name } })
      } catch (ex) {
        console.error(ex)

        this.$buefy.snackbar.open({
          message: this.$t(`users.messages.${type}.error`),
          type: 'is-danger',
          position: 'is-top',
          actionText: this.$t('common.retry'),
          cancelText: this.$t('common.cancel'),
          duration: 10000,
          onAction: () => {
            this.submit()
          }
        })
      } finally {
        this.isLoading = false
      }
    },
    cancel() {
      this.$emit('completed', { type: this.id ? 'update' : 'add', outcome: 'cancel' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
