import { post } from './httpService'
import { postToDownload } from './httpServiceToDownload'

const securedRequest = fn => (url, options) => {
  const apiKey = localStorage.getItem('apiKey')
  return fn(url, {
    ...options,
    headers: {
      ...(options && options.headers),
      Authorization: `Bearer ${apiKey}`
    }
  })
}

export const securePost = securedRequest(post)

export const securePostToDownload = securedRequest(postToDownload)
