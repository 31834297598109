<template>
  <div class="modal-card" style="width: auto">
    <form @submit.prevent="submit">
      <header class="modal-card-head">
        <p class="modal-card-title">{{productName}}</p>
      </header>
      <section class="modal-card-body">
        <b-field :label="$t('addProduct.fields.quantity')">
          <b-numberinput
            :placeholder="0"
            ref="newQty"
            v-model="newQty"
            size="is-large"
            min="0"
            @focus="$event.target.select()"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :label="$t('common.cancel')"
          @click="cancel" />
        <b-button
          :label="$t('common.accept')"
          type="is-primary"
          native-type="submit" />
      </footer>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AddProduct',
  props: ['productId', 'productName', 'price', 'qty', 'transferId'],
  data: () => ({
    newQty: 0,
    isLoading: false
  }),
  mounted () {
    this.newQty = this.qty

    this.$refs.newQty.focus()
  },
  methods: {
    cancel() {
      this.$emit('completed', { type: 'add-product', outcome: 'cancel' })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
