import { securePut } from './httpServiceSecure'

const context = 'transfers'

const add = data => securePut(`${context}`, { data })

const addDetails = (id, data) => securePut(`${context}/${id}/details`, { data })

export default {
  add,
  addDetails
}
