import { getCurrentLocationId } from '../utils/auth'
import { secureGet } from './httpServiceSecure'

const getContext = () => `locations/${getCurrentLocationId()}/bins`

const listDetailsByType = (binType, prices = 'unit') =>
  secureGet(`${getContext()}/${binType}/details?prices=${prices}`)

export default {
  listDetailsByType
}
