<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ $t('checkOut.saleTicket.title') }}
          </h1>
        </div>
      </div>
    </section>
    <!-- <section class="block">
      <div class="block is-flex is-flex-direction-row-reverse">
        <div class="buttons">
          <print-receipt v-if="!isLoading" :transaction="transactionData" :details="tableData" :total="total" />
        </div>
      </div>
    </section> -->
    <section class="block">
      <div class="columns">
        <div class="column is-4">
          <span class="title is-5">{{ $t('checkOut.saleTicket.correlative') }}</span>
        </div>
        <div class="column is-8">
          <span class="title is-5 has-text-weight-normal">{{ transactionData.correlative }}</span>
        </div>
      </div>
      <div class="columns" v-if="clientName">
        <div class="column is-4">
          <span class="title is-5">{{ $t('checkOut.edit.fields.clientName') }}</span>
        </div>
        <div class="column is-8">
          <span class="title is-5 has-text-weight-normal">{{ clientName }}</span>
        </div>
      </div>
    </section>
    <section class="block">
      <product-table
      :data="tableData"
      />
    </section>
    <section class="block">
      <div class="columns is-justify-content-flex-end">
        <div class="column is-3">
          <span class="title is-5">{{ $t('checkOut.edit.headers.total') }}</span>
        </div>
        <div class="column is-3">
          <span class="title is-5 has-text-weight-normal"> {{ total | toFormatMoney}} </span>
        </div>
      </div>
    </section>
    <section>
      <div class="columns">
        <div class="column is-3">
          <b-button
              type="is-primary"
              size="is-medium"
              @click="goToCheckOutOrder"
              >
              {{ $t('checkOut.saleTicket.goToCheckOutOrder') }}
            </b-button>
        </div>
      </div>
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import _ from 'lodash'
import Big from 'big.js'
import ProductTable from './ProductTable.vue'
//import PrintReceipt from './PrintReceipt.vue'
import orderService from '../../services/orderService'
export default {
  name: 'SaleTicket',
  components: {
    ProductTable
    //PrintReceipt
  },
  props: {
    id: {
      type: String,
      default: null
    },
    transactionData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tableData: [],
      total: '0',
      clientName: '',
      isLoading: true,
    }
  },
  async mounted () {
    const {data: orderServiceData } = await orderService.get(this.id)
    this.clientName = orderServiceData.clientName

    const { data } = await orderService.getDetails(this.id)
    const details = _.chain(data)
      .map(detail => {
        const orderQty = detail.qty / detail.priceQty
        return {
          productId: detail.productId,
          productName: this.$t('orders.details.cells.name', { name: detail.productName, qty: detail.priceQty }),
          totalQty: detail.qty,
          orderQty,
          priceId: detail.priceId,
          priceQty: detail.priceQty,
          priceType: detail.priceType,
          priceValue: detail.priceValue,
          totalPriceValue: Big(detail.priceValue).times(orderQty).toFixed(2)
        };
      })
      .value()

    this.tableData = details

    this.total = details.reduce(
      (sum, item) => sum.plus(item.totalPriceValue),
      Big(0)
    ).toFixed(2)

    this.isLoading = false
  },
  methods: {
    goToCheckOutOrder() {
      this.$router.push('/check-out')
    }
  }
}
</script>

