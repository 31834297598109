import _get from 'lodash/get'
import { getClaims } from '../utils/auth'

export default {
  data() {
    return {
      currentRole: undefined
    }
  },
  created() {
    this.currentRole = _get(getClaims(), 'user.role')
  }
}
