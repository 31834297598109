<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{$t(`${type}.title`)}}
          </h1>
          <h2 class="subtitle">
            {{$t(`${type}.subtitle`)}}
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="container block">
        <b-button
          :label="$t(`${type}.actions.add`)"
          type="is-primary"
          size="is-medium"
          @click="$router.push(`/${type}/new`)" />
      </div>
      <div class="container">
        <div class="columns">
          <div class="column is-4">
            <search-input
              :data="data"
              :fieldToSearch="nameField"
              @getResultOfSearchInput="(value) => detailsBySearch = value"
              />
          </div>
        </div>
        <b-table
          striped
          ref="table"
          :data="data"
          :loading="isLoading"
          :detailed="showDetails"
          :custom-detail-row="showDetails"
          :detail-key="showDetails ? details.key : undefined"
          :show-detail-icon="showDetails"
          :row-class="hiddenRow"
        >
          <b-table-column v-for="column in columns" :key="column.field" :field="column.field" :label="$t(`${type}.list.headers.${column.field}`)" v-slot="props">
            <a role="button" @click="editItem" :data-id="props.row.id" :data-field="column.field">
              {{ props.row[column.field] }}
            </a>
          </b-table-column>
          <b-table-column v-slot="props">
            <b-button type="is-danger" icon-right="trash" @click="deleteItem" :data-id="props.row.id" :data-name="props.row[nameField]" />
          </b-table-column>

          <template slot="detail" slot-scope="props">
            <tr v-for="item in props.row[details.field]" :key="item[details.key]">
              <td></td>
              <td v-for="column in details.columns" :key="column.field">
                {{ item[column.field] }}
              </td>
              <td></td>
            </tr>
          </template>
        </b-table>
      </div>
    </section>
    <b-modal
      v-model="showEditModal"
      has-modal-card>
      <router-view v-on:completed="onActionCompleted" />
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'

import apiServices from '../services/apiServices'
import SearchInput from './SearchInput/index.vue'
import { hiddenRowTable } from '../utils/hiddenRowTable'

export default {
  name: 'DataList',
  props: ['type', 'columns', 'details', 'nameField'],
  components: {
    SearchInput
  },
  data: () => ({
    data: [],
    isLoading: true,
    showEditModal: false,
    detailsBySearch: {}
  }),
  watch: {
    $route(newVal) {
      this.showEditModal = !!newVal.meta?.showEditModal

      newVal.meta?.showDeleteDialog && this.showDeleteDialog()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const formatFunctions = _.chain(this.columns)
        .filter('format')
        .keyBy('field')
        .mapValues('format')
        .value()

      const detailsFormatFunctions = _.chain(this.details)
        .get('columns', [])
        .filter('format')
        .keyBy('field')
        .mapValues('format')
        .value();

      const $t = this.$t.bind(this)

      apiServices[this.type].list().then(({ data }) => {
        this.data = data
          .map(item => {
            const detailsObject = this.details ? {
              [this.details.field]: item[this.details.field].map(
                detailItem => ({
                  ...detailItem,
                  ..._.mapValues(detailsFormatFunctions, fn => fn(detailItem, $t))
                })
              )
            } : {}

            return {
              ...item,
              ..._.mapValues(formatFunctions, fn => fn(item, $t)),
              ...detailsObject
            };
          })

        this.data = _.sortBy(this.data, ['name'])

        this.isLoading = false
      }).catch(error => console.error(error))
    },
    editItem(e) {
      const { id, field } = e.currentTarget.dataset
      this.$router.push(`/${this.type}/edit/${id}?field=${field}`)
    },
    deleteItem(e) {
      const { id, name } = e.currentTarget.dataset
      this.$router.push(`/${this.type}/delete/${id}?name=${name}`)
    },
    showDeleteDialog() {
      const id = this.$route.params.id
      const name = this.$route.query.name

      this.$buefy.dialog.confirm({
        message: this.$t(`${this.type}.messages.delete.confirm`, { name }),
        confirmText: this.$t('common.yes'),
        cancelText: this.$t('common.no'),
        onCancel: () => this.onActionCompleted({ type: 'delete', outcome: 'cancel' }),
        onConfirm: async () => {
          this.isLoading = true

          try {
            await apiServices[this.type].del(id)

            this.onActionCompleted({
              type: 'delete',
              outcome: 'success',
              data: { name }
            })
          } catch(error) {
            console.error(error)

            this.onActionCompleted({
              type: 'delete',
              outcome: 'error'
            })
          }
        }
      })
    },
    onActionCompleted(event) {
      const toastType = ({
        'success': 'is-success',
        'failure': 'is-danger'
      })[event.outcome]

      if (toastType) {
        this.$buefy.toast.open({
          message: this.$t(`${this.type}.messages.${event.type}.${event.outcome}`, event.data),
          type: toastType
        })
      }

      if (event.outcome === 'success') {
        this.fetchData()
      } else {
        this.isLoading = false
      }

      this.$router.back()
    },
    hiddenRow(row) {
      return hiddenRowTable(row, this.detailsBySearch, this.nameField)
    }
  },
  computed: {
    showDetails(){
      return !!this.details
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
