<template>
  <div class="block">
    <div class="b-table"><!----><!---->
      <div class="table-wrapper has-mobile-cards has-sticky-header table-container" style="height: 65vh;">
        <table class="table is-bordered is-striped">
          <thead>
            <tr>
              <th class="is-sticky vertical-align-middle" rowspan="2">
                <div class="th-wrap is-flex-direction-column">
                  <span class="is-relative"> {{ $t('reports.daily-sales.table.headers.name') }}
                  </span>
                </div>
              </th>

              <th v-for="date in dateRange" :key="`th_date_${date.getTime()}`" colspan="2">
                <div class="th-wrap is-flex-direction-column">
                  <span class="is-relative"> {{ date | dateFormat }}
                  </span>
                </div>
              </th>
            </tr>
            <tr>
              <template v-for="date in dateRange">
                <!--<th :key="`th_price_${date.getTime()}`">
                  <div class="th-wrap is-flex-direction-column">
                    <span class="is-relative"> {{ $t('reports.daily-sales.table.headers.price') }}
                    </span>
                  </div>
                </th>-->
                <th :key="`th_quantity_${date.getTime()}`">
                  <div class="th-wrap is-flex-direction-column">
                    <span class="is-relative"> {{ $t('reports.daily-sales.table.headers.quantity') }}
                    </span>
                  </div>
                </th>
                <th :key="`th_total_${date.getTime()}`">
                  <div class="th-wrap is-flex-direction-column">
                    <span class="is-relative"> {{ $t('reports.daily-sales.table.headers.total') }}
                    </span>
                  </div>
                </th>
              </template>
            </tr>
          </thead>
          <tbody v-show="!isEmptyData">
            <tr v-for="record in data" :key="record.id" draggable="false" class="">
              <td :data-label="$t('reports.daily-sales.table.headers.name')" class="is-sticky">
                <span>{{ record.name }}</span>
              </td>
              <template v-for="date in dateRange">
                <td :key="`td_date_${date.getTime()}`" :data-label="$t('reports.daily-sales.table.headers.date')" class="extra-fields-mobile">
                  <span>{{ date | dateFormat }}</span>
                </td>
                <!--<td :key="`td_price_${date.getTime()}`" :data-label="$t('reports.daily-sales.table.headers.price')">
                  <span>{{ record[`price_${date.getTime()}`] }}</span>
                </td>-->
                <td :key="`td_quantity_${date.getTime()}`" :data-label="$t('reports.daily-sales.table.headers.quantity')">
                  <span>{{ record[`quantity_${date.getTime()}`] }}</span>
                </td>
                <td :key="`td_total_${date.getTime()}`" :data-label="$t('reports.daily-sales.table.headers.total')">
                  <span>{{ record[`total_${date.getTime()}`] }}</span>
                </td>
              </template>
            </tr>
          </tbody>
          <tbody v-show="isEmptyData">
            <tr class="is-empty">
                <td :colspan="dateRange.length * 2 + 1">
                    <div class="has-text-centered">No existen registros que mostrar</div>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { dateToDDMYYYY } from '../../../utils/dateFormat'
export default {
  name: 'DailySaleTable',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    dateRange: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      height: '65vh',
      isEmptyData: false
    }
  },
  watch: {
    data(value) {
      this.isEmptyData = (value.length > 0) ? false : true
    }
  },
  filters: {
    dateFormat(value) {
      return dateToDDMYYYY(value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.vertical-align-middle {
  vertical-align: middle !important;
}
.b-table .table-wrapper.has-sticky-header tr:nth-child(2n) th {
  position: -webkit-sticky;
  position: sticky;
  top: 42px;
  z-index: 2;
  background: white;
    background-color: white;
    background-image: none;
}

@media screen and (min-width: 769px) {
  .extra-fields-mobile {
    display: none;
  }
}
</style>
