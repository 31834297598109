<template>
  <b-field>
    <b-input
      :placeholder="$t('common.search')"
      type="search"
      icon="search"
      icon-pack="fas"
      size="is-medium"
      v-model="valueToSearch"
      :icon-right="iconRight"
      icon-right-clickable
      @icon-right-click="clearIconClick">
    </b-input>
  </b-field>
</template>
<script>
import _ from 'lodash'
import { removeAccentCharacters } from '../../utils/removeAccentCharacters'

export default {
  name: 'SearchInput',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    fieldToSearch: {
      type: String,
      default: 'productName'
    }
  },
  data() {
    return {
      valueToSearch: '',
      iconRight: ''
    }
  },
  watch: {
    valueToSearch(value) {
      this.debouncedEmitResult(value)
    }
  },
  created: function() {
    this.debouncedEmitResult = _.debounce(this.emitResult, 250)
  },
  mounted() {
    this.$emit('getResultOfSearchInput', {
        isEmptyValue: true,
        searchedProducts: []
      }
    )
  },
  methods: {
    emitResult(value) {
      const isEmptyValue = !value.trim()

      if(!isEmptyValue) {
        this.iconRight = 'close-circle'
      }

      const searchedValue = removeAccentCharacters(value.trim().toLowerCase())

      const searchedProducts = this.data.filter(
        element => removeAccentCharacters(element[this.fieldToSearch].toLowerCase()).includes(searchedValue)
      )

      this.$emit('getResultOfSearchInput', {
        isEmptyValue,
        searchedProducts
      })
    },
    clearIconClick(){
      this.valueToSearch = ''
      this.iconRight = ''
    }
  }
}
</script>

