import { getCurrentLocationId } from '../utils/auth'
import { secureGet, securePatch, securePut } from './httpServiceSecure'

const getContext = () => `locations/${getCurrentLocationId()}/orders`

const listOrdersByStatus = status => secureGet(`${getContext()}?status=${status}`)

const get = id => secureGet(`${getContext()}/${id}`)

const add = data => securePut(`${getContext()}`, { data })

const patch = (id, data) => securePatch(`${getContext()}/${id}`, { data })

const getDetails = id => secureGet(`${getContext()}/${id}/details`)

export default {
  listOrdersByStatus,
  get,
  add,
  patch,
  getDetails
}
