import Vue from 'vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import './scss/main.scss'
import money from 'v-money'
import moment from 'moment'
import _ from 'lodash'

import {
  AddProduct,
  CheckOut,
  CheckOutOrder,
  ChooseProduct,
  ClosingInventory,
  EditOrder,
  EditProduct,
  EditUser,
  Home,
  Login,
  Main,
  Orders,
  Production,
  Products,
  Users,
  BeginningDailyInventory,
  SaleTicket,
  DailySale
} from './components'
import App from './App.vue'
import { isLoggedIn, getClaims, setRefreshApiKey } from './utils/auth'
import i18n from './i18n'
import { fetchInterceptor } from './utils/fetchInterceptor'
import { formatMoney } from './utils/format'

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultIconPack: 'fas'
})

Vue.use(VueRouter)

const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    name: 'main',
    path: '/',
    component: Main,
    children: [
      {
        name: 'home',
        path: 'home',
        component: Home,
        meta: {
          roles: ['admin', 'salesclerk', 'cashier', 'producer']
        }
      },
      {
        path: 'orders',
        component: Orders,
        meta: {
          roles: ['admin', 'salesclerk']
        },
        children: [
          {
            path: 'cancel/:id',
            meta: {
              roles: ['admin', 'salesclerk'],
              showCancelDialog: true
            }
          }
        ]
      },
      {
        path: 'orders/edit/:id',
        component: EditOrder,
        props: true,
        meta: {
          roles: ['admin', 'salesclerk']
        },
        children: [
          {
            path: 'choose-product',
            component: ChooseProduct,
            meta: {
              roles: ['admin', 'salesclerk'],
              showModal: true,
              hasModalCard: false
            }
          },
          {
            path: 'add-product',
            component: AddProduct,
            meta: {
              roles: ['admin', 'salesclerk'],
              showModal: true,
              hasModalCard: true
            }
          }
        ]
      },
      {
        path: 'check-out',
        component: CheckOut,
        meta: {
          roles: ['admin', 'cashier']
        },
        children: [
          {
            path: 'cancel/:id',
            meta: {
              roles: ['admin', 'salesclerk'],
              showCancelDialog: true
            }
          }
        ]
      },
      {
        path: 'check-out/:id',
        component: CheckOutOrder,
        props: true,
        meta: {
          roles: ['admin', 'cashier']
        },
        children: [
          {
            path: 'remove/products/:productId/prices/:priceId',
            meta: {
              roles: ['admin', 'cashier'],
              showRemoveDialog: true
            }
          }
        ]
      },
      {
        path: 'check-out/:id/sale-ticket',
        name: 'sale-ticket',
        component: SaleTicket,
        props: true,
        meta: {
          roles: ['admin', 'cashier']
        }
      },
      {
        path: 'products',
        component: Products,
        meta: {
          roles: ['admin', 'producer']
        },
        children: [
          {
            path: 'new',
            component: EditProduct,
            meta: {
              roles: ['admin', 'producer'],
              showEditModal: true
            }
          },
          {
            path: 'edit/:id',
            component: EditProduct,
            props: true,
            meta: {
              roles: ['admin', 'producer'],
              showEditModal: true
            }
          },
          {
            path: 'delete/:id',
            meta: {
              roles: ['admin', 'producer'],
              showDeleteDialog: true
            }
          }
        ]
      },
      {
        path: 'closing-inventory',
        component: ClosingInventory,
        meta: {
          roles: ['admin', 'cashier']
        }
      },
      {
        path: 'production',
        component: Production,
        meta: {
          roles: ['admin', 'producer']
        }
      },
      {
        path: 'users',
        component: Users,
        meta: {
          roles: ['admin']
        },
        children: [
          {
            path: 'new',
            component: EditUser,
            meta: {
              roles: ['admin'],
              showEditModal: true
            }
          },
          {
            path: 'edit/:id',
            component: EditUser,
            props: true,
            meta: {
              roles: ['admin'],
              showEditModal: true
            }
          },
          {
            path: 'delete/:id',
            meta: {
              roles: ['admin'],
              showDeleteDialog: true
            }
          }
        ]
      },
      {
        name: 'reports-beginning-daily-inventory',
        path: 'reports/beginning-daily-inventory',
        component: BeginningDailyInventory,
        meta: {
          roles: ['admin', 'producer']
        },
      },
      {
        name: 'reports-daily-sales',
        path: 'reports/daily-sales',
        component: DailySale,
        meta: {
          roles: ['admin', 'producer', 'cashier']
        },
      }
    ]
  }
]

const router =  new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (['login', 'main'].includes(to.name) && isLoggedIn()) {
    next({ name: 'home' })
  } else if (!to.meta?.allowAnonymous && !isLoggedIn()) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  } else if (to.meta?.allowAnonymous && !isLoggedIn()) {
    next()
  } else {
    const allowedRoles = _.get(to, 'meta.roles', [])
    const currentRole = _.get(getClaims(), 'user.role')
    // TODO: Could it be possible to set the current role in a global variable here, or pass it to the component?
    if (allowedRoles.includes('_') || allowedRoles.includes(currentRole)) {
      next()
    } else {
      next(false)
    }
  }
})

Vue.use(money, {
  prefix: '$ ',
  precision: 2
})


Vue.filter(
  'toLocalDateTime',
  (value, format) => moment(value).local().format(format)
)

Vue.filter(
  'toFormatMoney',
  (value) => formatMoney(value)
)

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')

if (isLoggedIn()) {
  setRefreshApiKey()
}

//Interceptor to redirect to login and clear localstorage items: apiKey
fetchInterceptor(router)
