export const dateToUnixEpoch = (date) => Math.floor(date.getTime() / 1000)

export const shortMonthNames = () => {
  return [
    'ene',
    'feb',
    'mar',
    'abr',
    'may',
    'jun',
    'jul',
    'ago',
    'sep',
    'oct',
    'nov',
    'dic'
  ]
}

export const dateToHumanReport = (date) => {
  const monthNames = shortMonthNames()

  return `${date.getDate()}-${monthNames[date.getMonth()]}-${date.getFullYear().toString().slice(-2)}`
}

export const dateToStartDay = (date) => {
  const startDate = new Date(date)

  startDate.setHours(0, 0, 0, 0)

  return startDate
}

export const dateToEndDay = (date) => {
  const endDate = new Date(date)

  endDate.setHours(23, 59, 59, 999)

  return endDate
}

export const dateToNextDay = (date) => {
  const nextDay = new Date(date)

  nextDay.setDate(nextDay.getDate() + 1)

  return nextDay
}

export const dateToDDMYYYY = (date) => {

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export const dateToBackDays = (date, days = 1) => {
  const backDay = new Date(date)

  backDay.setDate(backDay.getDate() - days)

  return backDay;
}
