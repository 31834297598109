<template>
  <div class="block">
    <b-table
      striped
      hoverable
      :data="data"
      :loading="isLoading"
      :selected.sync="selectedRow"
      @cellclick="handleCellClick"
    >
      <b-table-column field="correlative" :label="$t('orders.list.headers.correlative')" v-slot="props">
        {{ props.row.correlative }}
      </b-table-column>
      <b-table-column field="clientName" :label="$t('orders.list.headers.clientName')" v-slot="props">
        {{ props.row.clientName }}
      </b-table-column>
      <b-table-column field="itemQty" :label="$t('orders.list.headers.itemQty')" v-slot="props">
        {{ props.row.itemQty }}
      </b-table-column>
      <b-table-column field="createdBy" :label="$t('orders.list.headers.salesclerk')" v-slot="props">
        {{ props.row.createdBy }}
      </b-table-column>
      <b-table-column field="createdOn" :label="$t('orders.list.headers.createdOn')" v-slot="props">
        {{ props.row.createdOn | toLocalDateTime('lll') }}
      </b-table-column>
      <b-table-column v-if="!hideActionColumn" v-slot="props" :meta="{ type: 'action' }">
        <b-button type="is-danger" icon-right="ban" @click="cancelItem" :data-id="props.row.id" :data-correlative="props.row.correlative" />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash'

import orderService from '../services/orderService'

export default {
  name: 'OrderList',
  props: ['status', 'hideActionColumn'],
  data: () => ({
    data: [],
    isLoading: true,
    selectedRow: undefined
  }),
  watch: {
    $route(newVal) {
      newVal.meta?.showCancelDialog && this.showCancelDialog()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      orderService.listOrdersByStatus(this.status)
        .then(({ data }) => {
          this.data = _
            .chain(data)
            .defaultTo([])
            .map(({ createdBy, ...item }) => ({
              ...item,
              createdBy: `${createdBy.firstNames} ${createdBy.lastNames}`
            }))
            .orderBy('createdOn', 'desc')
            .value()
          this.isLoading = false
        })
        .catch(error => console.error(error))
    },
    handleCellClick(row, { meta }) {
      if (meta && meta.type === 'action') {
        return
      }

      this.$emit('rowclick', row)
    },
    cancelItem(e) {
      const { id, correlative } = e.currentTarget.dataset
      const path = this.status === 'preparing' ? 'orders' : 'check-out'
      this.$router.push(`/${path}/cancel/${id}?correlative=${correlative}`)
    },
    showCancelDialog() {
      const id = this.$route.params.id
      const correlative = this.$route.query.correlative

      this.$buefy.dialog.confirm({
        message: this.$t(`orders.messages.cancel.confirm`, { correlative }),
        confirmText: this.$t('common.yes'),
        cancelText: this.$t('common.no'),
        onCancel: () => this.onActionCompleted({ type: 'cancel', outcome: 'cancel' }),
        onConfirm: async () => {
          this.isLoading = true

          try {
            await orderService.patch(id, { status: 'cancelled' })

            this.onActionCompleted({
              type: 'cancel',
              outcome: 'success',
              data: { correlative }
            })
          } catch(error) {
            console.error(error)

            this.onActionCompleted({
              type: 'cancel',
              outcome: 'failure'
            })
          }
        }
      })
    },
    onActionCompleted(event) {
      const toastType = ({
        'success': 'is-success',
        'failure': 'is-danger'
      })[event.outcome]

      if (toastType) {
        this.$buefy.toast.open({
          message: this.$t(`orders.messages.${event.type}.${event.outcome}`, event.data),
          type: toastType
        })
      }

      if (event.outcome === 'success') {
        this.fetchData()
      } else {
        this.isLoading = false
      }

      this.$router.back()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
