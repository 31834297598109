<template>
  <div class="main">
    <b-select class="location-selector" :loading="isLocationsLoading" v-model.lazy="locationId">
      <option
        v-for="option in locations"
        :value="option.id"
        :key="option.id">
        {{ option.name }}
      </option>
    </b-select>
    <NavBar
    :isOpenMenu.sync="isOpenMenu"
    />
    <Menu
    :isOpenMenu.sync="isOpenMenu"
    />
    <div class="main-container container">
      <router-view />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import locationService from '../services/locationService'
import { getClaims, getCurrentLocationId } from '../utils/auth'
import Menu from './Menu.vue'
import NavBar from './NavBar.vue'

export default {
  name: 'Main',
  components: {
    Menu,
    NavBar
  },
  data() {
    return {
      isOpenMenu: false,
      isLocationsLoading: true,
      locations: [],
      locationId: getCurrentLocationId()
    }
  },
  watch: {
    locationId: function(value) {
      const selectedLocation = _.find(this.locations, { id: value })

      localStorage.setItem('location', JSON.stringify(selectedLocation))

      window.location.reload()
    }
  },
  async mounted () {
    this.isLocationsLoading = true

    const { data } = await locationService.list()

    const parsedJwt = getClaims()

    const locations = _.isEmpty(parsedJwt.user.locations)
      ? data
      : _.filter(data, ({ id }) => parsedJwt.user.locations.includes(id))

    this.locations = locations
    this.isLocationsLoading = false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main {
  flex: 1;
  display: flex;

  &-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .location-selector {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 40;
  }
}
</style>
