<template>
  <div class="container">
    <section class="section">
      <div class="block buttons is-flex-direction-row-reverse">
        <b-button
          :label="$t('common.cancel')"
          type="is-danger is-light"
          size="is-medium"
          @click="$router.go(-1)"
        />
      </div>
      <div class="block">
        <b-table
          striped
          :data="data"
          :loading="isLoading"
          hoverable
          focusable
          :selected.sync="selectedRow"
          @click="handleRowClick"
        >
          <b-table-column
            field="name"
            :label="$t('products.list.headers.name')"
            v-slot="props"
            searchable
          >
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            field="price"
            :label="$t('products.list.headers.price')"
            v-slot="props"
          >
            {{ props.row.price }}
          </b-table-column>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
import productService from "../services/productService"

export default {
  name: "ChooseProduct",
  data: () => ({
    data: [],
    selectedRow: undefined,
    isLoading: false
  }),
  async mounted() {
    this.isLoading = true

    try {
      const { data } = await productService.list()
      this.data = data
    } catch (ex) {
      console.error(ex)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    handleRowClick(row) {
      this.$emit('completed', {
        type: 'choose-product',
        outcome: 'success',
        data: {
          id: row.id,
          name: row.name,
          price: row.price
        }
      })
    },
    cancel() {
      this.$emit('completed', {
        type: 'choose-product',
        outcome: 'cancel'
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
