import { del, get, patch, post, put } from './httpService'

const securedRequest = fn => (url, options) => {
  const apiKey = localStorage.getItem('apiKey')
  return fn(url, {
    ...options,
    headers: {
      ...(options && options.headers),
      Authorization: `Bearer ${apiKey}`
    }
  })
}

export const secureGet = securedRequest(get)

export const securePost = securedRequest(post)

export const securePut = securedRequest(put)

export const securePatch = securedRequest(patch)

export const secureDel = securedRequest(del)
