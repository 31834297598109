<template>
  <div class="modal-card" style="width: auto">
    <form @submit.prevent="submit">
      <header class="modal-card-head">
        <p class="modal-card-title">{{$t('products.edit.headers.new')}}</p>
      </header>
      <section class="modal-card-body">
        <div class="block">
          <b-field grouped>
            <b-field :label="$t('products.edit.fields.name')">
              <b-input
                ref="name"
                v-model.lazy="name"
                :placeholder="$t('products.edit.fields.name')"
                @focus="$event.target.select()"
                required
              />
            </b-field>
          </b-field>
        </div>
        <div class="block">
          <b-table
            striped
            :data="priceData"
            :loading="isLoading"
            :custom-row-key="id"
          >
            <b-table-column field="qty" :label="$t('products.edit.fields.priceQuantity')" v-slot="props">
              <b-numberinput
                :placeholder="1"
                :min="1"
                v-model="props.row.qty"
                :loading="isLoading"
                :disabled="isLoading"
                required
              />
            </b-table-column>
            <b-table-column field="price" :label="$t('products.edit.fields.priceValue')" v-slot="props">
              <b-input
                v-model.lazy="props.row.price"
                v-money="moneyFormat"
                placeholder="0.00"
                @focus="$event.target.select()"
                required
              />
            </b-table-column>
            <b-table-column field="price" :label="$t('products.edit.fields.priceVipCustomer')" v-slot="props">
              <b-switch v-model="props.row.type" true-value="vip-customer" false-value="normal" />
            </b-table-column>
            <b-table-column v-slot="props">
              <b-button type="is-danger" icon-right="trash" @click="deletePrice" :data-id="props.row.id" />
            </b-table-column>

            <template #footer>
              <div class="has-text-right">
                <b-button type="is-link" icon-right="plus-circle" @click="addPrice" :label="$t('products.edit.actions.addPrice')" />
              </div>
            </template>
          </b-table>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :label="$t('common.cancel')"
          @click="cancel" />
        <b-button
          :label="id ? $t('common.update') : $t('common.add')"
          type="is-primary"
          native-type="submit" />
      </footer>
    </form>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import productService from '../services/productService';

export default {
  name: 'EditProduct',
  props: ['id'],
  data: () => ({
    name: '',
    priceData: [],
    isLoading: false,
    moneyFormat: {} // HACK: Seems to be required by the money directive
  }),
  async mounted () {
    if (this.id) {
      this.isLoading = true

      try {
        const { data } = await productService.get(this.id)
        this.name = data.name
        this.priceData = data.prices
        /*
        if (!_isEmpty(data.price)) {
          // HACK to set v-money value when using Buefy input
          this.$refs.price.$el.getElementsByTagName('input')[0].value = data.price
        }
        */
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    }

    this.$refs.name.focus()
  },
  methods: {
    async submit() {
      const prices = this.priceData.map(x => ({
        qty: x.qty,
        price: x.price.toString().split(' ')[1],
        type: x.type
      }))

      const arePricesUnique = _.chain(prices)
        .groupBy(x => `${x.qty}-${x.type}`)
        .omitBy(group => group.length == 1)
        .isEmpty()
        .value();

      if (!arePricesUnique) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('products.edit.messages.invalidDuplicatedPrices'),
          type: 'is-danger'
        })

        return;
      }

      const atLeastOneNormalUnitPrice = !!prices.find(x => x.qty === 1 && x.type === 'normal');

      if (!atLeastOneNormalUnitPrice) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('products.edit.messages.invalidNotNormalUnitPrice'),
          type: 'is-danger'
        })

        return;
      }

      this.isLoading = true

      const { type, action } = this.id ? {
        type: 'update',
        action: productService.update(this.id, {
          name: this.name,
          prices
        })
      } : {
        type: 'add',
        action: productService.add({
          name: this.name,
          prices
        })
      }

      try {
        const { data } = await action

        if(_.isEmpty(data)) {
          throw new Error()
        }

        this.$emit('completed', { type, outcome: 'success', data: { name: this.name } })
      } catch (ex) {
        console.error(ex)

        this.$buefy.snackbar.open({
          message: this.$t(`products.messages.${type}.error`),
          type: 'is-danger',
          position: 'is-top',
          actionText: this.$t('common.retry'),
          cancelText: this.$t('common.cancel'),
          duration: 10000,
          onAction: () => {
            this.submit()
          }
        })
      } finally {
        this.isLoading = false
      }
    },
    cancel() {
      this.$emit('completed', { type: this.id ? 'update' : 'add', outcome: 'cancel' })
    },
    deletePrice(e) {
      const { id } = e.currentTarget.dataset
      this.priceData = this.priceData.filter(x => x.id !== id)
    },
    addPrice() {
      const maxQty = _.maxBy(this.priceData, x => x.qty)?.qty || 0
      this.priceData = [
        ...this.priceData,
        {
          id: uuidv4().replace('-', ''),
          qty: maxQty + 1,
          price: "0",
          type: 'normal'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
