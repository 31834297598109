<template>
  <div class="block is-size-6">
    <b-table
      striped
      :data="data"
      :loading="isLoading"
      :row-class="hiddenRow"
    >
      <b-table-column field="productName" :label="$t('orders.details.headers.productName')" v-slot="props" :searchable="false">
        <span :class="{ 'has-text-info': props.row.priceType === 'vip-customer' }">
          {{ props.row.productName }}
        </span>
        <b-tooltip
          v-if="props.row.priceType === 'vip-customer'"
          :label="$t('orders.details.labels.vipCustomerPrice')"
          type="is-info">
          <b-icon
            icon="user-tag"
            type="is-info" />
        </b-tooltip>
      </b-table-column>
      <b-table-column field="priceValue" :label="$t('orders.details.headers.price')" v-slot="props">
        {{ props.row.priceValue | toFormatMoney }}
      </b-table-column>
      <b-table-column field="availableQty" :label="$t('orders.details.headers.availableQuantity')" v-slot="props">
        {{ props.row.availableQty }}
      </b-table-column>
      <b-table-column field="orderQty" :label="$t('orders.details.headers.quantity')" v-slot="props">
        <b-numberinput
          controls-position="compact"
          :placeholder="0"
          :min="0"
          v-model="props.row.orderQty"
          @input="updatedOrderQty(props)"
          :loading="props.row.locked"
          :disabled="props.row.locked"
        />
      </b-table-column>
      <b-table-column field="totalQty" :label="$t('orders.details.headers.totalQuantity')" v-slot="props">
        {{ props.row.totalQty }}
      </b-table-column>
      <b-table-column field="totalPriceValue" :label="$t('orders.details.headers.totalPrice')" v-slot="props">
        {{ props.row.totalPriceValue | toFormatMoney }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash'
import Big from 'big.js'
import binService from '../../services/binService'
import { hiddenRowTable } from '../../utils/hiddenRowTable'

export default {
  name: 'checkout-add-products',
  props: {
    id: {
      type: String,
      default: undefined
    },
    currentTransferId: {
      type: String,
      default: undefined
    },
    dataBySearch: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      data: [],
      isLoading: true
    }
  },
  async mounted() {
    this.isLoading = true

    try {
      const productInventory  = await binService.listDetailsByType('store', 'all')

      const details = _.chain(productInventory)
        .get('data', [])
        .map(({ productId, productName, qty: availableQty, priceId, priceType, priceQty, priceValue }) => ({
          productId,
          productName: this.$t('orders.details.cells.name', { name: productName, qty: priceQty }),
          availableQty,
          initialTotalQty: 0,
          totalQty: 0,
          orderQty: 0,
          priceId,
          priceType,
          priceQty,
          priceValue: Big(priceValue).toFixed(2),
          totalPriceValue: 0
        }))
        .sortBy('productName')
        .value();

      this.data = details

      this.$emit('getAllProductsModal', this.data)

    } catch (ex) {
      console.error(ex)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    // TODO: This is very similar to OrderDetailsEdit. A common component may be useful.
    updatedOrderQty(props) {
      const row = props.row
      const previousQty = _
        .chain(this.data)
        .find(x => x.productId === row.productId && x.priceId === row.priceId)
        .get('totalQty')
        .value()
      const rowTotalQty = row.orderQty * row.priceQty
      const diffQty = rowTotalQty - previousQty

      this.data = this.data.map(x => x.productId === row.productId ? {
        ...x,
        availableQty: x.availableQty - diffQty,
        totalQty: x.orderQty * x.priceQty,
        totalPriceValue: Big(x.priceValue).times(x.orderQty).toFixed(2)
      } : x)
      this.$emit('addproductdetails', props)
    },
    hiddenRow(row) {
      return hiddenRowTable(row, this.dataBySearch)
    }
  }
}
</script>

<style>

</style>
