<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{$t('closingInventory.title')}}
          </h1>
          <h2 class="subtitle">
            {{$t('closingInventory.subtitle')}}
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="columns is-justify-content-space-between">
          <div class="column is-4">
            <search-input
              :data="data"
              @getResultOfSearchInput="(value) => detailsBySearch = value"
              />
          </div>
          <div class="column is-6">
            <div class="buttons is-justify-content-right">
              <b-button
                type="is-primary"
                size="is-medium"
                v-on:click="apply"
                v-bind:loading="isLoading"
                :disabled="loadingUpdateData">
                {{$t('closingInventory.actions.apply')}}
              </b-button>
              <b-button
                type="is-info"
                size="is-medium"
                icon-left="redo-alt"
                @click="updateData"
                :loading="loadingUpdateData"
                :disabled="isLoading" />
            </div>
          </div>
        </div>
        <b-table
          striped
          :data="data"
          :loading="isLoading"
          :row-class="hiddenRow"
        >
          <b-table-column field="name" :label="$t('closingInventory.list.headers.product')" v-slot="props">
            {{ props.row.productName }}
          </b-table-column>
          <b-table-column field="price" :label="$t('closingInventory.list.headers.systemQuantity')" v-slot="props">
            {{ props.row.qty }}
          </b-table-column>
          <b-table-column field="price" :label="$t('closingInventory.list.headers.actualQuantity')" v-slot="props">
            <b-numberinput :placeholder="0" :min="0" v-model="props.row.actualQty" v-on:input="props.row.diffQty = props.row.actualQty - props.row.qty" />
          </b-table-column>
          <b-table-column field="price" :label="$t('closingInventory.list.headers.diffQuantity')" v-slot="props">
            {{
              props.row.diffQty === 0
                ? '0'
                : $t('closingInventory.list.cells.' + (props.row.diffQty > 0 ? 'missing' : 'extra'), { qty: Math.abs(props.row.diffQty) })
            }}
          </b-table-column>
        </b-table>
      </div>
    </section>
    <section>
      <div class="container">
        <b-button
          type="is-primary"
          size="is-medium"
          v-on:click="apply"
          v-bind:loading="isLoading"
          :disabled="loadingUpdateData">
          {{$t('closingInventory.actions.apply')}}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash'

import binService from '../services/binService'
import transferService from '../services/transferService'
import SearchInput from './SearchInput/index.vue'
import { hiddenRowTable } from '../utils/hiddenRowTable'
import { getCurrentLocationId } from '../utils/auth'

export default {
  name: 'ClosingInventory',
  data: () => ({
    data: [],
    isLoading: true,
    detailsBySearch: {},
    loadingUpdateData: false
  }),
  components: {
    SearchInput
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async apply() {
      await this.updateData();

      const details = this.data.filter(
          d => d.diffQty !== 0
        ).map(
          d => ({
            productId: d.productId,
            priceId: d.priceId,
            fromBinType: 'store',
            toBinType: 'missing',
            qty: -d.diffQty
          })
        )
      try {
        this.isLoading = true

        const locationId = getCurrentLocationId()

        const { data } = await transferService.add({
          type: 'closing_inventory',
          fromLocationId: locationId,
          toLocationId: locationId,
          details
        })

        if(_.isEmpty(data)) {
          throw new Error()
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('closingInventory.messages.applySuccess'),
          type: 'is-success'
        })

        await this.fetchData()
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    async fetchData() {
      await binService.listDetailsByType('store').then(({ data }) => {
        this.data = _.chain(data)
          .map(d => ({
            ...d,
            diffQty: 0,
            actualQty: d.qty
          }))
          .sortBy('productName')
          .value()

        this.isLoading = false
      }).catch(error => console.error(error))
    },
    hiddenRow(row) {
      return hiddenRowTable(row, this.detailsBySearch)
    },
    async updateData(){
      this.loadingUpdateData = true
      await binService.listDetailsByType('store').then(({ data }) => {
        for(let key in data) {
          data[key].diffQty = 0
          data[key].actualQty = data[key].qty
          let searchedData = this.data.filter(element => element.productId === data[key].productId)
          if(searchedData.length > 0) {
            data[key].actualQty = searchedData[0].actualQty
            data[key].diffQty = data[key].actualQty - data[key].qty
          }
        }
        this.data = _.chain(data)
          .sortBy('productName')
          .value()
        this.loadingUpdateData = false
      }).catch(error => console.error(error))
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
