import { clearApiKey } from './auth'

export const fetchInterceptor = router => {
  const fetch = window.fetch

  window.fetch = (...args) => (async (args) => {
    var result = await fetch(...args)
    if(result.status === 401 && router.history.current.path !== '/login') {
      clearApiKey()
      router.push('/login')
    }
    return result
  })(args)
}
