<template>
    <div>
      <line-chart :chartData="chartData" :chartOptions="chartOptions" v-if="!isLoading"/>

      <b-notification class="chart-skeleton" :closable="false" v-if="isLoading">
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
      </b-notification>
    </div>
</template>

<script>
//Components
import LineChart from '../Base/LineChart.vue'

//Functions
import _isEmpty from 'lodash/isEmpty'
import { dateToStartDay, dateToEndDay, dateToBackDays, dateToUnixEpoch, dateToDDMYYYY } from '../../../utils/dateFormat'
import { getUnixEpochRange } from '../../../utils/dateFunctions'
import { formatMoney } from '../../../utils/format'

//API
import DailySaleService from '../../../services/reports/dailySaleService'

export default {
  name: 'WeeklySales',
  components: {
    LineChart
  },
  data() {
    return {
      isLoading: true,
      dateFrom: null,
      dateTo: null,
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t('charts.weekly-sales.datasets-label'),
            backgroundColor: 'rgb(54, 162, 235)',
            data: [],
            borderColor: 'rgb(54, 162, 235)',
            pointRadius: 5,
            pointHoverRadius: 7
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.$t('charts.weekly-sales.title', {from: '', to: ''}),
            font: {
              size: 18
            }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return `${context.dataset.label}: ${formatMoney(context.parsed.y)}`
              },
              title: (context) => {
                return this.$t('charts.weekly-sales.tooltip-title', {period: context[0].label})
              }
            }
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: this.$t('charts.weekly-sales.scales-y-title'),
              font: {
                size: 14
              }
            },
            ticks: {
              callback: function(value) {
                return formatMoney(value);
              }
            }
          },
          x: {
            title: {
              display: true,
              text: this.$t('charts.weekly-sales.scales-x-title'),
              font: {
                size: 14
              }
            },
            ticks: {
              align: 'center'
            }
          }
        }
      }
    }
  },
  mounted() {
    this.getDailySales()
  },
  methods: {
    async getDailySales() {
      try {
        this.dateTo = dateToStartDay(Date.now())
        this.dateFrom = dateToBackDays(this.dateTo, 7)
        this.dateTo = dateToEndDay(this.dateTo)

        const parametersToSend = {
          parameters: {
            startDate: dateToUnixEpoch(this.dateFrom),
            endDate: dateToUnixEpoch(this.dateTo)
          }
        }

        const { data } = await DailySaleService.getList(parametersToSend)

        if(_isEmpty(data)) {
          throw new Error()
        }

        const dateRange = getUnixEpochRange(this.dateFrom, this.dateTo)

        for(let date in dateRange) {
          let startDate = dateRange[date]

          let dataFilter = data.data.filter(element => element[2] == dateToUnixEpoch(startDate))

          let price = dataFilter.reduce((previousValue, currentValue) => previousValue + parseFloat((currentValue[4] || 0)), 0)

          this.chartData.labels.push(dateToDDMYYYY(dateRange[date]))

          this.chartData.datasets[0].data.push(price)

        }

        this.chartOptions.plugins.title.text = this.$t('charts.weekly-sales.title', {from: dateToDDMYYYY(this.dateFrom), to: dateToDDMYYYY(this.dateTo)})

        this.isLoading = false

      } catch(error){
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.chart-skeleton {
  width: auto;
  height: 400px;
}
</style>
