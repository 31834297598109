<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{$t('production.title')}}
          </h1>
          <h2 class="subtitle">
            {{$t('production.subtitle')}}
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="columns is-justify-content-space-between">
          <div class="column is-4">
            <search-input
              :data="data"
              @getResultOfSearchInput="(value) => detailsBySearch = value"
              />
          </div>
          <div class="column is-6">
            <div class="buttons is-justify-content-right">
              <b-button
                type="is-primary"
                size="is-medium"
                v-on:click="apply"
                v-bind:loading="isLoading"
                :disabled="loadingUpdateData">
                {{$t('production.actions.apply')}}
              </b-button>
              <b-button
                type="is-info"
                size="is-medium"
                icon-left="redo-alt"
                @click="updateData"
                :loading="loadingUpdateData"
                :disabled="isLoading" />
            </div>
          </div>
        </div>
        <b-table
          striped
          :data="data"
          :loading="isLoading"
          :row-class="hiddenRow"
        >
          <b-table-column field="name" :label="$t('production.list.headers.product')" v-slot="props">
            {{ props.row.productName }}
          </b-table-column>
          <b-table-column field="lastProductionQty" :label="$t('production.list.headers.lastProductionQty')" v-slot="props">
            {{ props.row.lastProductionQty }}
          </b-table-column>
          <b-table-column field="price" :label="$t('production.list.headers.currentQuantity')" v-slot="props">
            {{ props.row.qty }}
          </b-table-column>
          <b-table-column field="price" :label="$t('production.list.headers.addQuantity')" v-slot="props">
            <b-numberinput :placeholder="0" :min="0" v-model="props.row.addQty" v-on:input="props.row.newQty = props.row.qty + props.row.addQty" />
          </b-table-column>
          <b-table-column field="price" :label="$t('production.list.headers.newQuantity')" v-slot="props">
            {{ props.row.newQty }}
          </b-table-column>
        </b-table>
      </div>
    </section>
    <section>
      <div class="container">
        <b-button
          type="is-primary"
          size="is-medium"
          v-on:click="apply"
          v-bind:loading="isLoading"
          :disabled="loadingUpdateData">
          {{$t('production.actions.apply')}}
        </b-button>
      </div>
    </section>
  </div>
</template>

<script>
import _ from 'lodash'

import binService from '../services/binService'
import transferService from '../services/transferService'
import SearchInput from './SearchInput/index.vue'
import { hiddenRowTable } from '../utils/hiddenRowTable'
import { getCurrentLocationId } from '../utils/auth'

export default {
  name: 'Production',
  data: () => ({
    data: [],
    isLoading: true,
    detailsBySearch: {},
    loadingUpdateData: false
  }),
  components: {
    SearchInput
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async apply() {
      const details = this.data.filter(
          d => d.addQty !== 0
        ).map(
          d => ({
            productId: d.productId,
            priceId: d.priceId,
            fromBinType: 'source',
            toBinType: 'store',
            qty: d.addQty
          })
        )
      try {
        this.isLoading = true

        const locationId = getCurrentLocationId()

        const { data } = await transferService.add({
          type: 'production',
          fromLocationId: locationId,
          toLocationId: locationId,
          details
        })

        if(_.isEmpty(data)) {
          throw new Error()
        }

        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('production.messages.applySuccess'),
          type: 'is-success'
        })

        this.fetchData()
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    fetchData() {
      binService.listDetailsByType('store').then(({ data }) => {
        this.data = _.chain(data)
          .map(d => ({
            ...d,
            addQty: 0,
            newQty: d.qty
          }))
          .sortBy('productName')
          .value()
        this.isLoading = false
      }).catch(error => console.error(error))
    },
    hiddenRow(row) {
      return hiddenRowTable(row, this.detailsBySearch)
    },
    updateData(){
      this.loadingUpdateData = true
      binService.listDetailsByType('store').then(({ data }) => {
        for(let key in data) {
          data[key].addQty = 0
          data[key].newQty = data[key].qty
          let searchedData = this.data.filter(element => element.productId === data[key].productId)
          if(searchedData.length > 0) {
            data[key].addQty = searchedData[0].addQty
            data[key].newQty = data[key].qty + searchedData[0].addQty
          }
        }
        this.data = _.chain(data)
          .sortBy('productName')
          .value()
        this.loadingUpdateData = false
      }).catch(error => console.error(error))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
