<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ $t('reports.beginning-daily-inventory.title') }}
          </h1>
          <h2 class="subtitle">
            {{ $t('reports.beginning-daily-inventory.subtitle') }}
          </h2>
        </div>
      </div>
    </section>
    <div :closable="false">
      <section>
        <div class="columns mb-5">
          <!-- Datepicker "from" -->
          <div class="column is-4">
            <b-field :label="$t('reports.beginning-daily-inventory.fields.datepicker.from')">
              <b-datepicker
                v-model="selectedDatePickerFrom"
                :locale="locale"
                :placeholder="$t('reports.beginning-daily-inventory.fields.datepicker.placeholder')"
                icon="calendar"
                trap-focus
                :max-date="maxDatePickerFrom">
              </b-datepicker>
            </b-field>
          </div>
          <!-- Datepicker "to" -->
          <div class="column is-4">
            <b-field :label="$t('reports.beginning-daily-inventory.fields.datepicker.to')">
              <b-datepicker
                v-model="selectedDatePickerTo"
                :locale="locale"
                :placeholder="$t('reports.beginning-daily-inventory.fields.datepicker.placeholder')"
                icon="calendar"
                trap-focus
                :max-date="maxDatePickerTo">
              </b-datepicker>
            </b-field>
          </div>
          <!-- Button show report -->
          <div class="column is-4 flex-button">
            <b-button
              type="is-primary"
              size="is-medium"
              @click="listDailyInventory"
              >
              {{ $t('reports.buttons.show') }}
            </b-button>
          </div>
        </div>
      </section>
      <section>
        <beginning-daily-inventory-table v-show="isVisibleTable"
        :data="tableData"
        :columns="columns"
        :dateRange="dateRange"
        />
      </section>
      <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
  </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty'
import { dateToUnixEpoch, dateToStartDay } from '../../utils/dateFormat'
import { getUnixEpochRangeStartDate } from '../../utils/dateFunctions'
import BeginningDailyInventoryService from '../../services/reports/beginningDailyInventoryService'
import { createTableStructure } from '../../utils/beginningDailyInventory/createTableStructure'
import BeginningDailyInventoryTable from './BeginningDailyInventoryTable.vue'
import { tableColumns } from '../../utils/beginningDailyInventory/tableColumns'

export default {
  name: 'BeginningDailyInventory',
  components: {
    BeginningDailyInventoryTable,
  },
  data() {
    return {
      selectedDatePickerFrom: new Date(),
      selectedDatePickerTo: new Date(),
      locale: "es-ES",
      tableData: [],
      columns: [],
      dateRange: [],
      isVisibleTable: false,
      isFullPage: false,
      isLoading: false,
      maxDatePickerTo: new Date(),
      maxDatePickerFrom: new Date(),
    }
  },
  watch: {
    selectedDatePickerTo(value) {
      this.maxDatePickerFrom = value
      if(this.selectedDatePickerFrom.getTime() > value.getTime()) {
        this.selectedDatePickerFrom = value
      }
    }
  },
  methods: {
    async listDailyInventory() {
      this.tableData = []
      this.columns = []
      this.dateRange = []
      this.isVisibleTable = false
      this.isLoading = true
      try {
        const startDate = dateToStartDay(this.selectedDatePickerFrom)

        const endDate = dateToStartDay(this.selectedDatePickerTo)

        const parameters = {
          parameters: {
            startDate: dateToUnixEpoch(startDate),
            endDate: dateToUnixEpoch(endDate)
          }
        }

        this.dateRange = getUnixEpochRangeStartDate(this.selectedDatePickerFrom, this.selectedDatePickerTo)

        this.columns = tableColumns(this.dateRange)

        const { data } = await BeginningDailyInventoryService.getList(parameters)

         if(_isEmpty(data)) {
          throw new Error()
        }

        this.tableData = createTableStructure(data, this.dateRange)

        this.isVisibleTable = true

        this.isLoading = false

      }
      catch(err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-button {
  display: flex;
  align-items: flex-end;
}
</style>
