<template>
  <div>
    <section class="hero">
      <div class="hero-body is-flex is-justify-content-space-between">
        <div class="container">
          <h2 class="subtitle">
            {{$t('checkOut.edit.headers.orderCorrelative')}}
          </h2>
          <h1 class="title">
            {{correlative}}
          </h1>
        </div>
        <div class="container">
          <h2 class="subtitle">
            {{$t('checkOut.edit.headers.total')}}
          </h2>
          <h1 class="title">
            {{ total | toFormatMoney }}
          </h1>
        </div>
      </div>
    </section>
    <section>
      <div class="block">
        <b-field :label="$t('checkOut.edit.fields.clientName')" custom-class="is-large">
          <b-input
            class="input-animated-icon"
            v-model="clientName"
            size="is-large"
            :placeholder="$t('checkOut.edit.fields.clientName')"
            :icon-right="isSavingClientName ? 'sync' : undefined"
          />
        </b-field>
      </div>
      <div class="block is-flex is-flex-direction-row-reverse">
        <div class="buttons">
          <b-button
            :label="$t(`checkOut.edit.actions.addProducts`)"
            type="is-danger"
            size="is-medium"
            @click="openAddProductsModal"
          />
          <b-button
            :label="$t(`checkOut.edit.actions.submit`)"
            type="is-success"
            size="is-medium"
            @click="submitTransaction"
            :disabled="isEmptyDetails"
          />
        </div>
      </div>
      <order-details
        ref="orderDetails"
        :id="id"
        :currentTransferId="currentTransferId"
        @detailsloaded="handleDetailsLoaded"
      />
    </section>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <b-modal v-model="isCardModalActive" full-screen scroll="keep">
      <div class="modal-card">
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-one-third">
              <search-input
                :data="allProducts"
                @getResultOfSearchInput="(value) => { detailsBySearch = value }"
              />
            </div>
          </div>
          <add-product
          :id="id"
          :currentTransferId="currentTransferId"
          @addproductdetails="handleAddProductDetails"
          @getAllProductsModal="(data) => allProducts = data"
          :dataBySearch="detailsBySearch"
          />
        </section>
      <footer class="modal-card-foot">
        <b-button
            :label="$t('common.cancel')"
            @click="isCardModalActive = false" />
        <b-button
            :label="$t('common.add')"
            type="is-primary"
            @click="fetchAddProducts"/>
      </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Big from 'big.js'

import OrderDetails from './OrderDetails.vue'
import AddProduct from '../components/CheckOut/AddProduct.vue'
import orderService from '../services/orderService'
import transactionService from '../services/transactionService'
import transferService from '../services/transferService'
import SearchInput from './SearchInput/index.vue'

export default {
  name: 'EditOrder',
  components: {
    'order-details': OrderDetails,
    'add-product': AddProduct,
    'search-input': SearchInput
  },
  props: ['id'],
  data: () => ({
    isLoading: false,
    correlative: undefined,
    clientName: undefined,
    savedClientName: undefined,
    isSavingClientName: false,
    details: [],
    total: "0",
    showModal: false,
    hasModalCard: false,
    modalProps: undefined,
    currentTransferId: undefined,
    selectedRow: undefined,
    transactionData: [],
    isCardModalActive: false,
    productsToAdd: [],
    isEmptyDetails: false,
    allProducts: [],
    detailsBySearch: {}
  }),
  async mounted () {
    if (!this.id) {
      return
    }

    await this.loadOrder()
  },
  watch: {
    $route(newVal) {
      this.showModal = !!newVal.meta?.showModal
      this.hasModalCard = !!newVal.meta?.hasModalCard
    },
    clientName: function(value) {
      this.debouncedSaveClientName(value)
    },
    details(value) {
      this.isEmptyDetails = !value.length
    }
  },
  created: function() {
    this.debouncedSaveClientName = _.debounce(this.saveClientName, 10000)
  },
  methods: {
    async loadOrder() {
      this.isLoading = true

      try {
        this.$refs.orderDetails.load()

        const { data: order } = await orderService.get(this.id)
        this.correlative = order.correlative
        this.clientName = order.clientName
        this.savedClientName = order.clientName
        this.currentTransferId = order.currentTransfer.id
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isLoading = false
      }
    },
    handleDetailsLoaded(details) {
      this.details = details
      this.total = details.reduce(
        (sum, item) => sum.plus(item.totalPriceValue),
        Big(0)
      ).toFixed(2)
    },
    async saveClientName(value) {
      if (this.savedClientName === value || this.isSavingClientName) {
        return
      }

      this.isSavingClientName = true

      try {
        orderService.patch(this.id, {
          clientName: value
        })
        this.savedClientName = value
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isSavingClientName = false
      }
    },
    async submitTransaction() {

      if(this.isEmptyDetails) return

      this.isLoading = true

      try {
        const { data } = await transactionService.add({
          orderId: this.id,
          details: this.details.map((detail, i) => ({
            productId: detail.productId,
            priceId: detail.priceId,
            lineNumber: i + 1,
            qty: detail.totalQty
          }))
        })

        if(_.isEmpty(data)) {
          throw new Error()
        }

        this.transactionData = data

        this.onActionCompleted({
          type: 'submit',
          outcome: 'success'
        })
      } catch(error) {
        console.error(error)

        this.onActionCompleted({
          type: 'submit',
          outcome: 'failure'
        })
      }
    },
    onActionCompleted(event) {
      const toastType = ({
        'success': 'is-success',
        'failure': 'is-danger'
      })[event.outcome]

      if (toastType) {
        this.$buefy.toast.open({
          message: this.$t(`checkOut.messages.${event.type}.${event.outcome}`, event.data),
          type: toastType
        })
      }

      if (event.outcome === 'success') {
        this.$router.push({
          name: 'sale-ticket',
          params: {
            id: this.id,
            transactionData: this.transactionData
          }
        })
        //this.$router.back()
      } else {
        this.isLoading = false
      }


    },
    handleAddProductDetails(addProductsDetails) {
      const getIndexProduct = this.productsToAdd
        .findIndex(element =>
          element.productId === addProductsDetails.row.productId
          && element.priceId === addProductsDetails.row.priceId)
      const qty = addProductsDetails.row.orderQty * addProductsDetails.row.priceQty

      if(getIndexProduct === -1) {
        this.productsToAdd.push({
          productId: addProductsDetails.row.productId,
          priceId: addProductsDetails.row.priceId,
          fromBinType: 'store',
          toBinType: 'picks',
          qty
        })
      } else {
        this.productsToAdd[getIndexProduct]['qty'] = qty
      }
    },
    openAddProductsModal() {
      this.isCardModalActive = true
      this.productsToAdd = []
    },
    async fetchAddProducts() {
      if (_.isEmpty(this.productsToAdd)) {
        return
      }

      this.isLoading = true

      this.productsToAdd = this.productsToAdd.filter(element => element.qty > 0)

      try {
        const { data } = await transferService.addDetails(this.currentTransferId, this.productsToAdd)

        if(_.isEmpty(data)) {
          throw new Error()
        }

      } catch (ex) {
        this.$buefy.snackbar.open({
          message: this.$t('checkOut.messages.addProducts.failure'),
          type: 'is-danger',
          position: 'is-top',
          duration: 10000
        })

        console.error(ex)
      }

      this.isCardModalActive = false

      this.isLoading = false

      await this.loadOrder()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.input-animated-icon .icon {
  animation: fa-spin 2s linear infinite;
}
</style>
