import moment from 'moment'
import jwt_decode from 'jwt-decode';

export const isLoggedIn = () => {
  const isThereApiKey = !!localStorage.getItem('apiKey')
  const apiKeyExpirationTimestamp = localStorage.getItem('apiKeyExpirationTimestamp')
  const isThereApiKeyExpirationTimestamp = !!apiKeyExpirationTimestamp
  const isApiKeyNotExpired = isThereApiKeyExpirationTimestamp && moment.unix(parseInt(apiKeyExpirationTimestamp)).diff(moment()) > 300  // 5 minutes to expire

  return isThereApiKey && (!isThereApiKeyExpirationTimestamp || isApiKeyNotExpired)
}

export const getClaims = (apiKey) => {
  const jwt = apiKey || localStorage.getItem('apiKey')
  const claims = jwt_decode(jwt)
  return claims;
}

export const setRefreshApiKey = () => {
  const apiKeyExpirationTimestamp = localStorage.getItem('apiKeyExpirationTimestamp')

  if (!apiKeyExpirationTimestamp) return

  const millisToExpire = moment.unix(parseInt(apiKeyExpirationTimestamp)).diff(moment()) * 1000

  if (millisToExpire - 60000 < 0) return

  const refreshTimeoutId = setTimeout(() => {
    // TODO: Refresh API key
    console.log('TODO: Refresh API key')

    clearRefreshApiKeyTimeout()
  }, millisToExpire)

  localStorage.setItem('apiKeyRefreshTimeoutId', refreshTimeoutId)
}

export const clearRefreshApiKeyTimeout = () => {
  const apiKeyRefreshTimeoutId = parseInt(localStorage.getItem('apiKeyRefreshTimeoutId'))
  clearTimeout(apiKeyRefreshTimeoutId)
}

//Remove localstorage item that begin by apiKey
export const clearApiKey = () => {
  localStorage.removeItem('apiKey')
  localStorage.removeItem('location')
  localStorage.removeItem('apiKeyExpirationTimestamp')
  localStorage.removeItem('apiKeyRefreshTimeoutId')
}

export const getCurrentLocation = () => JSON.parse(localStorage.getItem('location'))

export const getCurrentLocationId = () => {
  const currentLocation = getCurrentLocation()
  return currentLocation.id
}
